// 테스트넷 안하면 지워야함
export const addNetwork = async (networks) => {
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: networks.ChainId,
        chainName: networks.ChainName,
        nativeCurrency: networks.nativeCurrency,
        rpcUrls: networks.rpcUrls,
        blockExplorerUrls: networks.blockExplorerUrls,
      },
    ],
  });
};

export const addToken = async () => {
  const tokenAddress = process.env.VUE_APP_TOKEN;
  const tokenSymbol = "DLP";
  const tokenDecimals = 18;
  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image:
            "https://s.klayswap.com/data/img/token/0x4d87baa66061a3bb391456576cc49b77540b9aa9.png",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const accountCall = async () => {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    // alert("배열 : " + accounts[0]);
    // alert("문자 : " + accounts);
    return accounts[0];
  } catch (error) {
    console.log(error);
    // this.showMsgBoxTwo(a);
  }
};
