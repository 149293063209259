<template>
  <b-button class="smallIconColor align-items-center shadow-none">
    <img
      :src="require(`../../assets/img/${img}.svg`)"
      class="centerw2"
      alt="nav"
    />
    &nbsp;
    <div :class="`centerw ${text}`">
      {{ text1 }}
    </div>
  </b-button>
</template>
<script>
export default {
  name: "FootBotton",
  props: {
    img: String,
    text: String,
    text1: String,
  },
};
// variant="outline-light"
</script>
<style scoped>
.smallIconColor {
  position: relative;
  width: 20vw !important;
  color: #212529 !important;
  font-weight: 400 !important;
  background-color: white !important;
  border-color: #fff !important;
  /* border-width: 2px !important;
  border-radius: 10px !important; */
  padding: 5px 0 1px !important;
}
.centerw2 {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px !important;
  right: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 576px) {
  .centerw {
    font-size: 12px;
    color: #888888;
  }
}
@media screen and (max-width: 374px) {
  .centerw {
    font-size: 11px;
  }
}
</style>
