<template>
  <div class="text-center">
    <BIconStopwatch font-scale="3" animation="cylon"></BIconStopwatch>
    <p id="cancel-label">{{ contractStore.waitMsg }}</p>
    <b-button
      variant="outline-danger"
      size="sm"
      aria-describedby="cancel-label"
      @click="contractStore.overRay(false, true)"
    >
      Cancel
    </b-button>
  </div>
</template>
<script>
import { useContractStore } from "../stores/contractView";
import { BIconStopwatch } from "bootstrap-vue";
export default {
  name: "Overlay",
  components: { BIconStopwatch },
  setup() {
    const contractStore = useContractStore();

    return { contractStore };
  },
};
</script>
