import { defineStore } from "pinia";

export const useNotionStore = defineStore("notionS", {
  state: () => ({
    notificationNumList: [1, 3, 2, 4, 5, 6, 7, 8, 9],
    notification: [
      {
        main: "안녕하세요 테스트 공지입니다 클릭시 네이버로 이동",
        date: "2023-03-03",
        link: "http://www.naver.com",
        num: 0,
      },
    ],
  }),
  getters: {
    lengthN: (state) => state.notification.length,
    check: (state) => {
      return (i) => state.notification[i].num;
    },
    getMain: (state) => {
      return (num) => state.notification[num].main;
    },
    getDate: (state) => {
      return (num) => state.notification[num].date;
    },
    getLink: (state) => {
      return (num) => state.notification[num].link;
    },
    getCarousel: (state) => {
      return (num) => state.notification[num];
    },
  },

  actions: {
    numListCut() {
      this.notificationNumList.splice(0, 1);
    },
    numListCut1(msg) {
      this.notificationNumList.unshift(msg);
    },
    numListCopy(msg) {
      this.notificationNumList = msg;
    },
    notificationUp(msg) {
      this.notification.push(msg);
    },
    notificationCut(msg) {
      this.notification.splice(msg, 1);
    },
    notificationCopy(msg) {
      this.notification = msg;
    },
    updateMain(num, msg) {
      this.notification[num].main = msg;
    },
    updateDate(num, msg) {
      this.notification[num].date = msg;
    },
    updateLink(num, msg) {
      this.notification[num].link = msg;
    },
  },
});
