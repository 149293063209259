import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueI18n from "vue-i18n";
import { messages } from "./locales";
import { BootstrapVue } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

export const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "kr", // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
new Vue({
  i18n,
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
