<template>
  <!-- 사이드바 시작 -->
  <b-sidebar
    id="sidebar-right"
    width="310px"
    no-header
    right
    shadow
    bg-variant="white"
  >
    <template #default="{ hide }">
      <!-- 사이드바 상단 -->
      <nav class="px-3 py-2">
        <div class="position-relative d-flex mt-2 mb-3">
          <div class="fs-5 font171">
            {{ accountStore.userAddress }}
          </div>
          <!-- <MainCardBack :canvas="canvas" /> -->
          <div @click="hide(), basic()">
            <BIconX scale="2" class="position-absolute end-0"></BIconX>
          </div>
        </div>
        <!-- 사이드바 상단 끝 -->
        <BarCommon
          @click.native="go(0, '/'), hide()"
          :imgLink="'house24B.svg'"
          :text="$t('message.newSide10')"
        />
        <!-- 멤버십 -->
        <div class="pb-4">
          <div
            :class="`d-flex justify-content-between align-items-center ${sideColor1}`"
            v-b-toggle.collapse-a1.collapse-b1
            @click="(lenguageUpDown1 += 180), sideColorChange1(sideVisible1)"
          >
            <div class="d-flex align-items-center">
              <img
                src="../../assets/img/group_add_opsz24B.svg"
                alt="nav"
                class="iconSize"
                v-if="sideVisible1 == false"
              />
              <img
                src="../../assets/img/group_add_opsz24.svg"
                alt="nav"
                class="iconSize"
                v-else
              />
              <button :class="`mx-2  ${sideColor1}`">
                {{ $t("message.dlp21") }}
              </button>
            </div>
            <BIconCaretDownFill
              :rotate="lenguageUpDown1"
              :class="sideVisible1 ? 'sideGray' : 'sideBlack'"
            ></BIconCaretDownFill>
          </div>
          <b-collapse
            id="collapse-a1"
            :aria-expanded="sideVisible1 ? 'true' : 'false'"
            v-model="sideVisible1"
          >
            <div class="pointer" @click="go(2, '/membership'), hide()">
              <div class="marginLeft child">{{ $t("message.newSide4") }}</div>
            </div>
          </b-collapse>
          <b-collapse
            id="collapse-b1"
            :aria-expanded="sideVisible1 ? 'true' : 'false'"
            v-model="sideVisible1"
          >
            <div class="pointer" @click="go(1, '/membership'), hide()">
              <div class="marginLeft child">{{ $t("message.newSide3") }}</div>
            </div>
          </b-collapse>
        </div>
        <!-- 멤버십 끝 -->
        <BarCommon
          @click.native="go(4, '/stake'), hide()"
          :imgLink="'lock_clock_opsz24B.svg'"
          :text="$t('message.newSide11')"
        />
        <BarCommon
          @click.native="go(5, '/swap'), hide()"
          :imgLink="'swap_horizontal_circle_opsz24B.svg'"
          :text="$t('message.dlp22')"
        />
        <!-- 게임 -->
        <div class="pb-4">
          <div
            :class="`d-flex justify-content-between align-items-center ${sideColor2}`"
            v-b-toggle.collapse-a2.collapse-b2
            @click="(lenguageUpDown2 += 180), sideColorChange2(sideVisible2)"
          >
            <div class="d-flex align-items-center">
              <img
                src="../../assets/img/stadia_controller_opsz24B.svg"
                alt="nav"
                class="iconSize"
                v-if="sideVisible2 == false"
              />
              <img
                src="../../assets/img/stadia_controller_opsz24.svg"
                alt="nav"
                class="iconSize"
                v-else
              />
              <button :class="`mx-2  ${sideColor2}`">
                {{ $t("message.newSide14") }}
              </button>
            </div>
            <BIconCaretDownFill
              :rotate="lenguageUpDown2"
              :class="sideVisible2 ? 'sideGray' : 'sideBlack'"
            ></BIconCaretDownFill>
          </div>
          <b-collapse
            id="collapse-a2"
            :aria-expanded="sideVisible2 ? 'true' : 'false'"
            v-model="sideVisible2"
          >
            <div class="pointer" @click="go(7, '/rock'), hide()">
              <div class="marginLeft child">{{ $t("message.newSide12") }}</div>
            </div>
          </b-collapse>
          <b-collapse
            id="collapse-b2"
            :aria-expanded="sideVisible2 ? 'true' : 'false'"
            v-model="sideVisible2"
          >
            <div class="pointer" @click="go(8, '/lottery'), hide()">
              <div class="marginLeft child">{{ $t("message.newSide13") }}</div>
            </div>
          </b-collapse>
        </div>
        <!-- 게임 끝 -->

        <!-- 토큰추가 -->
        <BarCommon
          @click.native="$emit('addTokenCall'), hide()"
          class="addDlp"
          :imgLink="'LOGO_ GRAY.png'"
          :text="$t('message.newSide9')"
        />
        <!-- 토큰추가 끝 -->
        <!-- 언어변경 -->
        <div class="pb-4">
          <div
            :class="`d-flex justify-content-between align-items-center ${sideColor}`"
            v-b-toggle.collapse-a.collapse-b
            @click="(lenguageUpDown += 180), sideColorChange(sideVisible)"
          >
            <div class="d-flex align-items-center">
              <img
                src="../../assets/img/language_white_24dp.svg"
                alt="nav"
                class="iconSize"
                v-if="sideVisible == false"
              />
              <img
                src="../../assets/img/language_color_24dp.svg"
                alt="nav"
                class="iconSize"
                v-else
              />
              <button :class="`mx-2  ${sideColor}`">
                {{ $t("message.newSide8") }}
              </button>
            </div>
            <BIconCaretDownFill
              :rotate="lenguageUpDown"
              :class="sideVisible ? 'sideGray' : 'sideBlack'"
            ></BIconCaretDownFill>
          </div>
          <b-collapse
            id="collapse-a"
            :aria-expanded="sideVisible ? 'true' : 'false'"
            v-model="sideVisible"
          >
            <!-- <div @click="$emit('languageChangEn')" class="pointer"> -->
            <div @click="$i18n.locale = 'en'" class="pointer">
              <div class="marginLeft child">English</div>
            </div>
          </b-collapse>
          <b-collapse
            id="collapse-b"
            :aria-expanded="sideVisible ? 'true' : 'false'"
            v-model="sideVisible"
          >
            <!-- <div @click="$emit('languageChangKr')" class="pointer"> -->
            <div @click="$i18n.locale = 'kr'" class="pointer">
              <div class="marginLeft child">한국어</div>
            </div>
          </b-collapse>
        </div>
        <!-- 언어변경 끝 -->

        <!-- 관리자 -->
        <BarCommon
          @click.native="go(3, '/admin'), hide()"
          :imgLink="'admin_panel_settings_opsz24B.svg'"
          :text="$t('message.newSide5')"
          v-if="adminStore.areYou"
        />
        <!-- 관리자 끝 -->
        <!-- 사이드바 닫기 -->
        <div
          class="sideHeight"
          @click="hide(), basic()"
          aria-controls="collapse-b"
        >
          .
        </div>
        <!-- 사이드바 닫기 끝 -->
        <footer class="sideFix p-3 iconColor1 addEDTP smallFF">
          <div class="mb-2">DeLiquidity LLC, SVG</div>
          <div>Copyright © 2021-2023 DeLiquidity.</div>
          <div>All rights reserved.</div>
        </footer>
      </nav>
    </template>
  </b-sidebar>
  <!-- 사이드바 끝 -->
  <!-- 로그아웃 끝 -->
</template>

<script>
import BarCommon from "./BarCommon.vue";
import { BIconX, BIconCaretDownFill } from "bootstrap-vue";
// import MainCardBack from "../mainCard/Back.vue";
import { useAccountStore } from "../../stores/account";
import { useAdminStore } from "../../stores/adminCheck";
import { usePageStore } from "../../stores/nextPage";
import { check } from "../../control/admin";
export default {
  name: "Sidebar",
  components: {
    // MainCardBack,
    BarCommon,
    BIconX,
    BIconCaretDownFill,
  },
  emits: ["addTokenCall"],
  setup() {
    const accountStore = useAccountStore();
    const adminStore = useAdminStore();
    const pageStore = usePageStore();

    return { accountStore, adminStore, pageStore };
  },
  data() {
    return {
      lenguageUpDown: 0,
      sideColor: "sideBlack",
      sideVisible: false,
      lenguageUpDown1: 0,
      sideColor1: "sideBlack",
      sideVisible1: false,
      lenguageUpDown2: 0,
      sideColor2: "sideBlack",
      sideVisible2: false,
      check,
    };
  },
  methods: {
    sideColorChange(num) {
      switch (num) {
        case true:
          this.sideColor = "sideBlack";
          break;
        case false:
          this.sideColor = "sideGray";
          break;
      }
    },
    sideColorChange1(num) {
      switch (num) {
        case true:
          this.sideColor1 = "sideBlack";
          break;
        case false:
          this.sideColor1 = "sideGray";
          break;
      }
    },
    sideColorChange2(num) {
      switch (num) {
        case true:
          this.sideColor2 = "sideBlack";
          break;
        case false:
          this.sideColor2 = "sideGray";
          break;
      }
    },
    // 다른 라우터이동 생길 수 있으니까 변수사용
    go(where, path) {
      this.pageStore.gogo(where);
      if (this.$route.path !== path) this.$router.push({ path: path });
      this.sideColor = "sideBlack";
      this.sideVisible = false;
      this.lenguageUpDown = 0;
    },
    basic() {
      this.sideVisible = false;
      this.lenguageUpDown = 0;
      this.sideColor = "sideBlack";

      this.sideVisible1 = false;
      this.lenguageUpDown1 = 0;
      this.sideColor1 = "sideBlack";

      this.sideVisible2 = false;
      this.lenguageUpDown2 = 0;
      this.sideColor2 = "sideBlack";
    },
  },
};
</script>
<style scoped>
.addDlp {
  border-top: 1px solid #80808099;
  padding-top: 1.5rem !important;
}
.sideFix {
  position: absolute;
  bottom: 0;
  left: 0;
}
.smallFF {
  font-size: 12px;
}
.font171 {
  color: black;
  font-weight: 700 !important;
}
.sideGray {
  background-color: #ffde5f24 !important;
  color: #ffde5f !important;
  border-style: initial;
  font-size: 18px;
}
.marginLeft {
  margin-left: 2.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  background-color: white !important;
  border-color: white !important;
  color: #888888 !important;
  font-size: 16px;
}
@media screen and (max-width: 1023px) {
  .sideHeight {
    height: 60vh;
    color: white;
    background-color: white;
  }
}
</style>
