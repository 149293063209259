import { callWeb3 } from "./getWeb3";

export const timeCut = (time) => {
  const shortTime = new Date(time * 1000);
  // .toString().slice(0, 15)

  return (
    shortTime.getFullYear() +
    "-" +
    (shortTime.getMonth() + 1) +
    "-" +
    shortTime.getDate()
  );
};

export const getRemainTime = (stakeTime, nowSeconds, waiting) => {
  const time = Number(stakeTime);
  if (time === 0) {
    return null;
  } else {
    const remainTime =
      nowSeconds - time > waiting
        ? waiting
        : nowSeconds - time > 0
        ? nowSeconds - time
        : 0;

    return remainTime;
  }
};

export const getRemainDay = (stakeTime, nowSeconds, waiting) => {
  let time = getRemainTime(stakeTime, nowSeconds, waiting);

  const day = time / 86400;
  return parseInt(day);
};

// 스테이킹 중인 정보 확인
export const seeData = async (stakeTime, waiting, wallet) => {
  //스테이킹한 시간
  const stakingTime = stakeTime;
  const time = Number(stakeTime);
  const userWaitingTime = Number(waiting);

  if (time != 0 && waiting != 0) {
    const addressToDepositTime = timeCut(time);

    const will1 = time + userWaitingTime;
    const will = timeCut(will1);
    // will1 이랑 nowSeconds 비교해서 위드로우랑 24위드로우 변경 (주의사항까지)
    // console.log("#$^%$^&$%&$%$&$^#$%", this.will1);

    const nowSeconds = await getNowTime(wallet);
    const remainTime = getRemainDay(stakeTime, nowSeconds, waiting);
    //더 크면 시간

    // const seconds2 = time + this.setWaitingTime;
    const seconds2 = time + 3600;
    // const seconds2 = time + 180;

    const daySeconds = seconds2;

    return [
      stakingTime,
      addressToDepositTime,
      will1,
      will,
      nowSeconds,
      remainTime,
      daySeconds,
    ];
  }
  return [stakingTime];
};

export const getNowTime = async (wallet) => {
  const web3 = await callWeb3(wallet);
  if (wallet === "true") {
    const block = await web3.eth.getBlock("latest");
    return block.timestamp;
  } else {
    const block = await web3.klay.getBlock("latest");
    return block.timestamp;
  }

  // const seconds = Math.floor(Date.now() / 1000);
  // return Number(seconds);
  // this.nowSeconds = Number(seconds);
  // console.log("지금시간", this.nowSeconds);
  // this.remainTime = getRemainTime(stakeTime, this.nowSeconds, waiting);
};

export const timeSetRS = (time, waitingDay) => {
  const remainTime = time >= waitingDay ? waitingDay : time;

  const seeRemain = waitingDay - remainTime;
  return [remainTime, seeRemain];
};
