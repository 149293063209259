import { getMembershipContract } from "./contractData";
import { fromWei, BN } from "./fromWei";
import { convertToObj, objectFilter } from "./addressFillter";

export const getUserInfo = async (address, wallet) => {
  const contract = await getMembershipContract(wallet);
  const info = await contract.methods.userInfos(address).call();
  return info;
};

export const getOwner = async (wallet) => {
  const contract = await getMembershipContract(wallet);
  let owner = await contract.methods.owner().call();
  return owner.toLowerCase();
  // this.contractOwner =
};

export const getEarn = async (account, wallet) => {
  const contract = await getMembershipContract(wallet);
  const earn = await contract.methods.earned(account).call();
  return earn;
};

export const rewardAddress = async (wallet) => {
  const contract = await getMembershipContract(wallet);
  let reward = await contract.methods.rewardsAddress().call();
  return reward.toLowerCase();
};

// 전체 토큰가격 한번에 불러오기
export const getDepositData = async (wallet) => {
  const contract = await getMembershipContract(wallet);
  const depositData = await contract.methods.seeMembershipTokenAmount().call();
  const result = await contract.methods.membershipInfos(1).call(); // 멤버십 1번 브론즈의 달러가격
  // console.log(result);
  const depositFee50 = [
    depositData[0],
    depositData[1],
    depositData[2],
    depositData[3],
  ];
  // 나중에 체인에 있는거 불러와서 할거면 / 100000 해야함. ($1 = 100000 으로 계산해둠) ㅇ
  // 4000 달러 기준으로 해서 1달러 구하기 위해(현재 4000달러 는 4000 * 100000의 값으로 체인에 기록되어 있음(소수점 다섯번째 자리까지 달러 가격을 계산하기 위해))
  let a = BN(depositFee50[1]);
  // let b = BN(4000);
  let b = BN(Number(result[1]) / 100000);

  // console.log(b);
  const USD = fromWei(a.div(b));
  return [depositFee50, USD];
};

export const stakingTimeWait = async (wallet) => {
  const contract = await getMembershipContract(wallet);
  const waiting = await contract.methods.waitingTime().call();
  return waiting;
};

//컨트랙트를 이용한 전체 계정의 계정 주소 배열, 등급 배열 가져오기
export const seeAddressToGrade = async (waiting, wallet) => {
  const contract = await getMembershipContract(wallet);
  const arr = await contract.methods.seeAddressToGrade().call();
  const address = arr[0];
  const grade = arr[1];
  const sum = convertToObj(address, grade);

  // this.objectFilter(sum);
  let list = [];
  let resultGrade = [];

  if (sum) {
    list = await objectFilter(sum, waiting, wallet);

    const no = [{ address: "None", amount: null, end: null, start: null }];

    resultGrade[999] = list[0].length === 0 ? no : list[0];
    resultGrade[1] = list[1].length === 0 ? no : list[1];
    resultGrade[2] = list[2].length === 0 ? no : list[2];
    resultGrade[3] = list[3].length === 0 ? no : list[3];
    resultGrade[4] = list[4];
  }
  return resultGrade;
};
