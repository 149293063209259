import { fromWei } from "./fromWei";
import { getEarn } from "./contractMembership";

const claimable = (getAPY, getBalances) => {
  const apy = getAPY;
  const balances = getBalances;

  // 1 / 100 초에 지급해야하는 이자양(setInterva()반복 시간과 연관)
  // 1년 (1000밀리초 * 60초 * 60분 * 24시간 * 365일)
  // 이율 = 1 / 100
  // setInterval 10 = 10 밀리초마다 반복
  // = 1000 / 10(10밀리초마다 반복) * 60 * 60 * 24 * 365 * 100(이율)
  let onePlus = (balances * apy) / 315360000000;
  onePlus = onePlus.toFixed(0);

  return onePlus;
};

const earnShow = async (earn, apy, balances) => {
  // 여기
  let showEarn0 = Number(fromWei(earn));
  let onePlus = claimable(apy, balances);
  return [Number(fromWei(onePlus)), showEarn0];
  // this.showOnePlusBefore = ;
  // this.showEarnBefore = ;
};

export const getEarnShow = async (
  userInfo,
  membershipApy,
  USD,
  account,
  wallet
) => {
  const earn = await getEarn(account, wallet);
  let showOnePlusBefore;
  let showEarnBefore;

  if (userInfo.grade == 999 || userInfo.grade == 0) {
    const result = await earnShow(earn, 0, userInfo.balances);
    showOnePlusBefore = result[0];
    showEarnBefore = result[1];
  } else {
    const result = await earnShow(
      earn,
      membershipApy[userInfo.grade - 1],
      userInfo.balances
    );
    showOnePlusBefore = result[0];
    showEarnBefore = result[1];
  }
  // 1토큰당 가격
  // this.dollar = 1 / USD;
  const dollar = 1 / USD;
  return [dollar, showEarnBefore, showOnePlusBefore];
};
