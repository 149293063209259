import { defineStore } from "pinia";
// import { colorChangeFunction, colorBasic } from "../control/colorChange";

export const usePageStore = defineStore("pageS", {
  state: () => ({
    nextPage: 0,
    // first visit
    mainFV: false, //?
    membershipFV: false,
    stakeFV: false,
    swapFV: false,
    rockFV: false,
    lotteryFV: false,
    adminFV: false,
    // colorChange: colorBasic,
    showSelect: false,
  }),

  actions: {
    setting(msg) {
      this.nextPage = msg;
    },

    gogo(num) {
      sessionStorage.setItem("nextPage", num);
      if (num === 0 || num === 1 || num === 2) {
        this.nextPage = num;
      }
      // this.colorChange = colorChangeFunction(num);
    },
    setMainVisit() {
      this.mainFV = true;
    },
    setMembershipVisit() {
      this.membershipFV = true;
    },
    setAdminVisit() {
      this.adminFV = true;
    },
    setStakeVisit() {
      this.stakeFV = true;
    },
    setSwapVisit() {
      this.swapFV = true;
    },
    setRockVisit() {
      this.rockFV = true;
    },
    setLotteryVisit() {
      this.lotteryFV = true;
    },
    setShowSelect(msg) {
      this.showSelect = msg;
    },
  },
});
