import { defineStore } from "pinia";

export const useWidthStore = defineStore("widthS", {
  state: () => ({
    windowWidth: window.innerWidth,
  }),

  actions: {
    async setting(msg) {
      this.windowWidth = msg;
    },
  },
});
