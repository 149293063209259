import { callWeb3, callWeb31 } from "./getWeb3";
import membershipABI from "../assets/data/membershipABI.json";
import tokenABI from "../assets/data/DLPTokenABI.json";
import rockABI from "../assets/data/RockABI.json";
import lotteryABI from "../assets/data/lotteryABI.json";

//바이넨스
export const tokenAddress = process.env.VUE_APP_TOKEN;
export const membership = process.env.VUE_APP_MEMBERSHIP;
export const rock = process.env.VUE_APP_ROCK;
export const lottery = process.env.VUE_APP_LOTTERY;

export const getChainId = async () => {
  const web3 = await callWeb31("true");
  const network1 = await web3.eth.getChainId();
  return network1;
};
export const getContract = async (wallet) => {
  // const network1 = await web3.eth.getChainId();
  const web3 = await callWeb3(wallet);
  try {
    if (wallet === "true") {
      const network1 = await web3.eth.getChainId();
      const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);
      const membershipContract = await new web3.eth.Contract(
        membershipABI,
        membership
      );
      const twoCN = [tokenContract, network1, membershipContract];
      return twoCN;
    } else {
      const network1 = window.klaytn.networkVersion;
      const tokenContract = await new web3.klay.Contract(
        tokenABI,
        tokenAddress
      );
      const membershipContract = await new web3.klay.Contract(
        membershipABI,
        membership
      );
      const twoCN = [tokenContract, network1, membershipContract];
      return twoCN;
    }
  } catch (error) {
    console.log(error);
    return false;
  }

  // console.log(myContract)
};

// 데이터 얻을 때 node
export const getTokenContract = async (wallet) => {
  const web3 = await callWeb3(wallet);
  if (wallet === "true") {
    const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);
    return tokenContract;
  } else {
    const tokenContract = await new web3.klay.Contract(tokenABI, tokenAddress);
    return tokenContract;
  }
};

// 트잭 날릴 때 window
export const getTokenContract1 = async (wallet) => {
  const web3 = await callWeb31(wallet);
  if (wallet === "true") {
    const tokenContract = await new web3.eth.Contract(tokenABI, tokenAddress);
    return tokenContract;
  } else {
    const tokenContract = await new web3.klay.Contract(tokenABI, tokenAddress);
    return tokenContract;
  }
};
// 데이터 얻을 때 node
export const getMembershipContract = async (wallet) => {
  const web3 = await callWeb3(wallet);
  if (wallet === "true") {
    const membershipContract = await new web3.eth.Contract(
      membershipABI,
      membership
    );
    return membershipContract;
  } else {
    const membershipContract = await new web3.klay.Contract(
      membershipABI,
      membership
    );
    return membershipContract;
  }
};
// 트잭 날릴 때 window
export const getMembershipContract1 = async (wallet) => {
  const web3 = await callWeb31(wallet);
  if (wallet === "true") {
    const membershipContract = await new web3.eth.Contract(
      membershipABI,
      membership
    );
    return membershipContract;
  } else {
    const membershipContract = await new web3.klay.Contract(
      membershipABI,
      membership
    );
    return membershipContract;
  }
};

export const getRockContract = async () => {
  const wallet = sessionStorage.getItem("wallet");
  const web3 = await callWeb3(wallet);

  if (wallet === "true") {
    const RockContract = await new web3.eth.Contract(rockABI, rock);
    return RockContract;
  } else {
    const RockContract = await new web3.klay.Contract(rockABI, rock);
    return RockContract;
  }
};

export const getRockContract1 = async () => {
  const wallet = sessionStorage.getItem("wallet");
  const web3 = await callWeb31(wallet);

  if (wallet === "true") {
    const RockContract = await new web3.eth.Contract(rockABI, rock);
    return RockContract;
  } else {
    const RockContract = await new web3.klay.Contract(rockABI, rock);
    return RockContract;
  }
};

export const getLotteryContract = async () => {
  const wallet = sessionStorage.getItem("wallet");
  const web3 = await callWeb3(wallet);

  if (wallet === "true") {
    const LotteryContract = await new web3.eth.Contract(lotteryABI, lottery);
    return LotteryContract;
  } else {
    const LotteryContract = await new web3.klay.Contract(lotteryABI, lottery);
    return LotteryContract;
  }
};

export const getLotteryContract1 = async () => {
  const wallet = sessionStorage.getItem("wallet");
  const web3 = await callWeb31(wallet);

  if (wallet === "true") {
    const LotteryContract = await new web3.eth.Contract(lotteryABI, lottery);
    return LotteryContract;
  } else {
    const LotteryContract = await new web3.klay.Contract(lotteryABI, lottery);
    return LotteryContract;
  }
};
