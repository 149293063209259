<template>
  <SortButton
    :sortText="sortText"
    :showSort="showSort"
    :langOrSort="false"
    @showSort1="showSort1"
  >
    <button
      slot="button3"
      v-for="(m, n) in buttonList"
      :key="n"
      class="hiddenButton"
      @click="
        (showSort = !showSort),
          (sortText = m),
          n == 0 ? ($i18n.locale = 'kr') : ($i18n.locale = 'en')
      "
    >
      {{ m }}
    </button>
  </SortButton>
</template>
<script>
import SortButton from "./SortButton.vue";
export default {
  name: "LangList",
  components: {
    SortButton,
  },
  data() {
    return {
      buttonList: ["KO", "EN"],
      sortText: "KO",
      showSort: false,
    };
  },
  methods: {
    showSort1(msg) {
      this.showSort = msg;
    },
  },
};
</script>

<style scoped>
.hiddenButton {
  color: black;
  text-align: left;
  flex-shrink: 0;
  padding: 0px 17px;
  height: 40px;
  transition: all 0.25s ease 0s;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.4px;
  background-color: white;
  border: none;
}
.hiddenButton:hover {
  font-weight: 900;
}
</style>
