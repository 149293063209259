<template>
  <nav class="buttonSide bottomMenu">
    <FootBotton
      @click.native="nextPage(0, '/')"
      :img="moveImgList.c"
      :text="moveImgList.a1"
      :text1="$t('message.newSide10')"
    />
    <FootBotton
      @click.native="nextPage(2, '/membership')"
      :img="moveImgList.a"
      :text="moveImgList.a3"
      :text1="$t('message.newSide4')"
    />
    <FootBotton
      @click.native="nextPage(1, '/membership')"
      :img="moveImgList.b"
      :text="moveImgList.a2"
      :text1="$t('message.newSide3')"
    />

    <FootBotton
      @click.native="nextPage(7, '/rock')"
      :img="moveImgList.d"
      :text="moveImgList.a4"
      :text1="$t('message.newSide12')"
    />
    <FootBotton
      @click.native="nextPage(8, '/lottery')"
      :img="moveImgList.e"
      :text="moveImgList.a5"
      :text1="$t('message.newSide13')"
    />
  </nav>
</template>
<script>
import FootBotton from "./FootBotton.vue";
import { usePageStore } from "../../stores/nextPage";
import { colorBasicS, footColor } from "../../control/colorChange";
export default {
  name: "SmallMove",
  components: {
    FootBotton,
  },
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  mounted() {
    const page = sessionStorage.getItem("nextPage");
    if (page) {
      this.moveImgList = this.footColor(page);
    } else {
      switch (this.$route.path) {
        case "/rock":
          this.moveImgList = this.footColor("7");
          break;
        case "/lottery":
          this.moveImgList = this.footColor("8");
          break;
      }
    }
  },
  data() {
    return {
      moveImgList: colorBasicS,
      footColor,
    };
  },
  methods: {
    nextPage(num, path) {
      this.pageStore.gogo(num);
      if (this.$route.path !== path) this.$router.push({ path: path });

      this.moveImgList = this.footColor(String(num));
    },
  },
};
</script>
<style scoped>
.bottomMenu {
  z-index: 999;
  justify-content: space-between !important;
  border-top: 1px solid rgb(222, 226, 230);
  position: fixed !important;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: min-content;
  padding: 0 20px;
  background-color: #fff;
}
</style>
