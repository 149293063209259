import { defineStore } from "pinia";
import { check } from "../control/admin";
import { membership } from "../control/contractData";
import { myBalance, allowance } from "../control/contractToken";
import { fromWei } from "../control/fromWei";

import { useContractStore } from "./contractView";
import { tvlChange } from "../control/tvlChange";
import {
  rewardAddress,
  seeAddressToGrade,
} from "../control/contractMembership";

export const useAdminStore = defineStore("adminS", {
  state: () => ({
    areYou: false,
    balance: null,
    TVL: null,
    resultGrade: [],
    readyForInterest: 0,
    addressToDateTF: false,
    rewardPaymentAccount: "0xFdf6C2124737E2FE188dA6F61d0A2fe8395d5eE",
    rewardAllow: null,
    rewardBalanceOf: null,
  }),

  actions: {
    async setting(account) {
      const result = await check(account);
      this.areYou = result;
    },
    // 관리자 계정으로 접속시
    async adminCall() {
      const wallet = sessionStorage.getItem("wallet");

      const contractStore = useContractStore();

      // this.waitingSeeData = true;
      const mefBalance = await myBalance(membership, wallet);
      const balanceDollar = mefBalance * contractStore.dollar;
      // this.TVL = balanceDollar.toFixed(0);
      //
      const tvl1 = balanceDollar.toFixed(0);
      this.setBalance(tvlChange(mefBalance));
      this.setTVL(tvlChange(tvl1));

      this.setResultGrade(
        await seeAddressToGrade(contractStore.waiting, wallet)
      );
      this.setReadyForInterest(this.resultGrade[4]);
      this.setAddressToDateTF(true);

      const result = await rewardAddress(wallet);
      this.setRewardPaymentAccount(result);

      try {
        let aloow = await allowance(membership, result, wallet);
        aloow = fromWei(aloow);
        this.setRewardAllow(Number(aloow).toFixed(0));
        let balance = await myBalance(result, wallet);
        this.setRewardBalanceOf(Number(balance).toFixed(0));
      } catch (error) {
        console.log(error);
      }
    },

    setBalance(msg) {
      this.balance = msg;
    },
    setTVL(msg) {
      this.TVL = msg;
    },
    setResultGrade(msg) {
      this.resultGrade = msg;
    },
    setReadyForInterest(msg) {
      this.readyForInterest = msg;
    },
    setAddressToDateTF(msg) {
      this.addressToDateTF = msg;
    },
    setRewardPaymentAccount(msg) {
      this.rewardPaymentAccount = msg;
    },
    setRewardAllow(msg) {
      this.rewardAllow = msg;
    },
    setRewardBalanceOf(msg) {
      this.rewardBalanceOf = msg;
    },
  },
});
