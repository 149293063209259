import { timeCut, getNowTime } from "./time";
import { getUserInfo } from "./contractMembership";
import { fromWei, BN } from "./fromWei";
import { UserAddress } from "./userAddress";

// 배열 두개 오브젝트로 합치기
export const convertToObj = (a, b) => {
  if (a.length != b.length || a.length == 0 || b.length == 0) {
    return null;
  }
  let obj = {};

  // (element, index)
  a.forEach((k, i) => {
    obj[k] = b[i];
  });
  return obj;
  // return Object.assign(...a.map((k, i) => ({ [k]: b[i] })))
};

const addressToDate = async (address, waiting1, wallet) => {
  let data = [];
  let membershipApy = [0, 6, 8, 10];
  membershipApy[999] = 0;
  let sumInterest = 0;

  const blockTime = await getNowTime(wallet);

  for (let i = 0; i < address.length; i++) {
    const userInfo = await getUserInfo(address[i], wallet);
    /////여기 구조체에서 가져오기
    const money = userInfo.balances;
    const lastUpdate = Number(userInfo.lastUpdateTime);
    const grade = Number(userInfo.grade);
    const time = userInfo.stakeTime;
    let start = timeCut(time);

    const waiting = waiting1;
    const timesum = Number(time) + Number(waiting);
    let end = timeCut(timesum);

    if (time == "0") {
      start = null;
      end = null;
    }

    let forSeeDeposit = fromWei(money);
    forSeeDeposit = Number(forSeeDeposit).toFixed(0);

    data.push({
      address: UserAddress(address[i]),
      amount: forSeeDeposit,
      start: start,
      end: end,
    });

    const a = Number(money);
    const b = ((blockTime - lastUpdate) * membershipApy[grade]) / 3153600000;
    const c = a * b;
    if (typeof c === "number") {
      sumInterest += Math.floor(c);
    }
  }
  sumInterest = sumInterest.toString();
  return [data, sumInterest];
};

//오브젝트 벨류값으로 필터 후 키 배열
export const objectFilter = async (sumObject, waiting, wallet) => {
  // 오브젝트 필터 정의
  Object.filter = function (mainObject, filterFunction) {
    return Object.keys(mainObject).filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    });
    // .reduce(function (result, ObjectKey) {
    //   result[ObjectKey] = mainObject[ObjectKey];
    //   return result;
    // }, {});
  };

  //오브젝트 필터
  // var targetSubjects = Object.filter(sumObject, (sumObject) => {
  //   return  sumObject == this.grade1
  // })
  var targetSubjects1 = Object.filter(sumObject, (sumObject) => {
    return sumObject == 1;
  });
  var targetSubjects2 = Object.filter(sumObject, (sumObject) => {
    return sumObject == 2;
  });
  var targetSubjects3 = Object.filter(sumObject, (sumObject) => {
    return sumObject == 3;
  });

  var targetSubjects = Object.filter(sumObject, (sumObject) => {
    return sumObject == 999;
  });

  let sum = BN(0);
  // const result1 = await addressToDate(Object.keys(targetSubjects1), waiting);
  // const resultGrade1 = result1[0];
  // sum += Number(result1[1]);

  const result1 = await addressToDate(targetSubjects1, waiting, wallet);
  const resultGrade1 = result1[0];
  sum = sum.add(BN(toFixed(result1[1])));

  const result2 = await addressToDate(targetSubjects2, waiting, wallet);
  const resultGrade2 = result2[0];
  sum = sum.add(BN(toFixed(result2[1])));

  const result3 = await addressToDate(targetSubjects3, waiting, wallet);
  const resultGrade3 = result3[0];
  sum = sum.add(BN(toFixed(result3[1])));

  const result = await addressToDate(targetSubjects, waiting, wallet);
  const resultGrade = result[0];

  const readyPay = fromWei(sum);

  const fixed = Number(readyPay).toFixed(4);

  return [resultGrade, resultGrade1, resultGrade2, resultGrade3, fixed];
};

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);

    // 지수 표현을 e+21 부터 시작해서. (1e21 = "1e+21", 1e20= "100000000000000000000")
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      // 지수표현을 안해도 되는 자리수 까지만 남김 (21자리의 수)

      // e 개수만큼 뒤에 0을 붙이는 하는 방법
      // undefined e + 1 개 배열을 0으로 채움 = e 개수만큼 0이 만들어짐
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
