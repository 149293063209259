import { api } from "../control/axios";

export const axiosCall = async () => {
  const result = await api("/api/notification", "get");
  return result;
};

export const callBasic = async () => {
  const list = await api("/api/banner", "get");

  return list;
};
