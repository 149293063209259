export const addToken1 = async () => {
  const tokenAddress = process.env.VUE_APP_TOKEN;
  const tokenSymbol = "DLP";
  const tokenDecimals = 18;
  try {
    await window.klaytn.sendAsync({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image:
            "https://s.klayswap.com/data/img/token/0x4d87baa66061a3bb391456576cc49b77540b9aa9.png",
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAccount1 = async () => {
  try {
    const accounts = await window.klaytn.enable();
    return accounts[0];
  } catch (error) {
    console.error(error);
  }
};
