<template>
  <footer
    v-if="widthStore.windowWidth >= 1024"
    class="text-center iconColor1 addEDTP footer"
  >
    <div>
      <div class="my-2">DeLiquidity LLC, SVG</div>
      <div class="mb-2">
        Copyright © 2021-2023 DeLiquidity. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import { useWidthStore } from "../../stores/width";
export default {
  name: "Footer",

  setup() {
    const widthStore = useWidthStore();

    return { widthStore };
  },
};
</script>

<style scoped>
.footer {
  margin-left: calc(-50vw + 50%);
  margin-bottom: -1rem;
  width: calc(100vw + 1rem);
  height: 140px;
  display: grid;
  place-items: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
}
</style>
