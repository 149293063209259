<template>
  <header class="headerB d-flex alignCenter">
    <div class="pointer" @click="pageStore.gogo(0), route()">
      <div class="d-flex align-items-center">
        <img class="mb-1" src="../../assets/img/LOGO_ S.png" alt="logo" />
        <span class="dustar">
          <div>DE:LIQUIDITY</div>
        </span>
      </div>
    </div>

    <div class="menu" @mouseleave="showDrop(false), showDrop1(false)">
      <b-dropdown
        ref="dropdown"
        variant="white"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <button @mouseenter="showDrop(true)">
            {{ $t("message.dlp21") }}
          </button>
        </template>
        <b-dropdown-item-button @click="gogo(2, '/membership')">{{
          $t("message.newSide4")
        }}</b-dropdown-item-button>
        <!-- <b-dropdown-divider></b-dropdown-divider> -->
        <b-dropdown-item-button @click="gogo(1, '/membership')">{{
          $t("message.newSide3")
        }}</b-dropdown-item-button>
      </b-dropdown>

      <button @click="gogo(4, '/stake')">{{ $t("message.newSide11") }}</button>
      <button @click="gogo(5, '/swap')">{{ $t("message.dlp22") }}</button>
      <b-dropdown
        ref="dropdown1"
        variant="white"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <button @mouseenter="showDrop1(true)">
            {{ $t("message.newSide14") }}
          </button>
        </template>
        <b-dropdown-item-button @click="gogo(7, '/rock')">{{
          $t("message.newSide12")
        }}</b-dropdown-item-button>
        <!-- <b-dropdown-divider></b-dropdown-divider> -->
        <b-dropdown-item-button @click="gogo(8, '/lottery')">{{
          $t("message.newSide13")
        }}</b-dropdown-item-button>
      </b-dropdown>
      <button @click="gogo(3, '/admin')" v-if="adminStore.areYou">
        {{ $t("message.newSide5") }}
      </button>
    </div>
    <div class="buttonRadius tool">
      <TopCommon v-if="switchLA" :text="accountStore.userAddress" />
      <b-button
        class="btn buttonColor buttonFontSize btn-secondary topBtn"
        v-else
        @click="logIn()"
      >
        connect wallet
      </b-button>
      <b-button
        size="sm"
        class="buttonBorder mx-1"
        @click="$emit('addTokenCall')"
      >
        <BIconWallet2 class="marginRight" font-scale="1.5"></BIconWallet2>
        DLP
      </b-button>
      <div class="lang"><LangList /></div>
    </div>
  </header>
</template>

<script>
import TopCommon from "./TopCommon.vue";
import LangList from "./LangList.vue";
import { BIconWallet2 } from "bootstrap-vue";
import { useAccountStore } from "../../stores/account";
import { usePageStore } from "../../stores/nextPage";
import { useAdminStore } from "../../stores/adminCheck";
export default {
  name: "Big",
  components: {
    TopCommon,
    LangList,
    BIconWallet2,
  },
  emits: ["addTokenCall"],
  props: {
    switchLA: Boolean,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const adminStore = useAdminStore();
    return { accountStore, pageStore, adminStore };
  },
  methods: {
    logIn() {
      this.pageStore.setShowSelect(true);
    },
    gogo(num, path) {
      this.pageStore.gogo(num);
      if (this.$route.path !== path) this.$router.push({ path: path });
    },
    route() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
    showDrop(or) {
      if (or) {
        this.$refs.dropdown.show(true);
      } else {
        this.$refs.dropdown.hide(true);
      }
    },
    showDrop1(or) {
      if (or) {
        this.$refs.dropdown1.show(true);
      } else {
        this.$refs.dropdown1.hide(true);
      }
    },
  },
};
</script>
<style scoped>
button {
  background-color: white;
  border: none;
  color: #212529;
  font-size: 18px;
  font-weight: 500;
  /* line-height: 0.5; */
}
button:hover {
  color: #ffde5f;
}

.tool {
  position: absolute;
  right: 0;
  display: flex;
}

.headerB {
  position: relative;
  height: 73px;
  width: clamp(1000px, 85vw, 2000px);
  right: 0;
  left: 0;
  margin: auto;
}
.buttonRadius :hover {
  background-color: #ffde5f24 !important;
  /* #e6ecf7 */
  border-radius: 10px 10px 10px 10px;
}
.menu {
  display: flex;
  justify-content: space-between;
  width: clamp(400px, 20%, 800px);

  margin-left: 5%;
}
.lang {
  display: grid;
  place-items: center;
  margin-left: 1em;
  color: #212529;
  font-size: 18px;
  font-weight: 500;
}
</style>
