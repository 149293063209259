// 컨트랙트에 저장된 등급 확인
export const switchGrade = (grade) => {
  switch (grade) {
    case "0":
      return "none";
    case "1":
      return "Ocean Blue";
    case "2":
      return "Cherry Red";
    case "3":
      return "Tropical Sun";
    case "999":
      return "none";
  }
};

// 내 등급 확인
export const myGrade = (grade) => {
  if (grade == 999) {
    return 0;
  } else {
    return grade;
  }
};

const gradeToNum = (grade) => {
  let gradeNum = Number(grade);
  if (gradeNum === 999) {
    gradeNum = 0;
  }
  return gradeNum;
};

export const gradeToText = (grade) => {
  const num = gradeToNum(grade);
  const gradeList = ["none", "Ocean Blue", "Cherry Red", "Tropical Sun"];
  const imageList = ["card5.png", "card0.png", "card1.png", "card2.png"];
  const nowGrade = gradeList[num];
  const gradeToImage = imageList[num];
  return [nowGrade, gradeToImage];
};
