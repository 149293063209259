import { defineStore } from "pinia";
import { useWidthStore } from "./width";
import { useBannerStore } from "../stores/banner";
import { allowance, myBalance } from "../control/contractToken";
import {
  getDepositData,
  stakingTimeWait,
  getUserInfo,
} from "../control/contractMembership";
import { membership } from "../control/contractData";
import { seeData, timeSetRS } from "../control/time";
import { radioConTF } from "../control/switchGrid";
import { fromWei, toWei } from "../control/fromWei";
import { getEarnShow } from "../control/claimable";
import { myGrade, gradeToText } from "../control/grade";

export const useContractStore = defineStore("contractS", {
  state: () => ({
    waiting: 15552000,
    dollar: 0,
    showWait: false,
    //
    depositFee50: [],
    USD: null,
    contractAllowance: 0,
    refresh: null,
    dollarEarn: null,
    showEarn: null,
    nowGrade: "none",
    addressToDeposit: null,
    depositD: null,
    upgradeUser: null,
    onChainGrade: 0,

    stakingTime: null,
    addressToDepositTime: null,
    will1: null,
    will: null,
    nowSeconds: null,
    daySeconds: null,
    remainTime: null,
    seeRemain: 0,
    waitingDay: 180,
    options: [
      { item: "3", name: "Tropical Sun", notEnabled: false },
      { item: "2", name: "Cherry Red", notEnabled: false },
      { item: "1", name: "Ocean Blue", notEnabled: false },
    ],
    totalReward: 0,
    membershipApy: [6, 8, 10],
    myTokenBalance: null,
    isMetamask: true,
    forReDollor: null,
    forReEarn: null,
    forReOnePlus: null,
    waitMsg: "Please wait transaction...",
  }),
  actions: {
    async setWaiting(wallet) {
      this.waiting = await stakingTimeWait(wallet);
    },
    setRefresh() {
      const widthStore = useWidthStore();
      let a;
      this.refresh = setInterval(() => {
        this.forReEarn += this.forReOnePlus;
        // 가지고 있는 토큰 개수 * 1토큰당 가격
        a = this.forReEarn * this.dollar;
        if (widthStore.windowWidth >= 1024) {
          this.dollarEarn = a.toFixed(18);
          this.showEarn = this.forReEarn.toFixed(18);
        } else {
          this.dollarEarn = a.toFixed(14);
          this.showEarn = this.forReEarn.toFixed(14);
        }
      }, 10);
    },
    delRefresh() {
      clearInterval(this.refresh);
    },
    async start(account) {
      this.overRay(true, false);
      const wallet = sessionStorage.getItem("wallet");
      const result = await getDepositData(wallet);

      this.setFee50(result[0]);
      this.setUSD(result[1]);

      await this.setWaiting(wallet);

      const allow = await allowance(membership, account, wallet);
      this.setAllow(allow);

      const userInfo = await this.beforeTx(wallet, account);

      this.radioControl();

      await this.afterTx(userInfo, wallet, account);
      this.overRay(false, false);
    },

    async beforeTx(wallet, account) {
      const userInfo = await getUserInfo(account, wallet);

      await this.seeDataSmall(userInfo.stakeTime, this.waiting, wallet);

      this.setDeposit(userInfo.balances);

      try {
        this.setDepositD(fromWei(userInfo.balances));
      } catch (error) {
        this.setDepositD("0");
      }

      this.setUpuser(userInfo.upgradeOrNew);
      this.setOnGrade(myGrade(userInfo.grade));
      this.switchGradeToText(userInfo.grade);

      return userInfo;
    },
    async seeDataSmall(stakingTime, waiting, wallet) {
      const result = await seeData(stakingTime, waiting, wallet);
      //여기까지지ㅣ지
      this.setStakingTime(result[0]);
      this.setAddressToDepositTime(result[1]);
      this.setWill1(result[2]);
      this.setWill(result[3]);
      this.setNowSeconds(result[4]);
      this.setDaySeconds(result[6]);

      const time1 = timeSetRS(result[5], this.waitingDay);
      this.setRemainTime(time1[0]);
      this.setSeeRemain(time1[1]);
    },
    //컨트랙트에 내 등급에 따른 등급 이름
    switchGradeToText(grade) {
      const bannerStore = useBannerStore();

      const result = gradeToText(grade);
      this.setNowGrade(result[0]);
      bannerStore.changeImg(result[1]);
    },

    radioControl() {
      const result = radioConTF(this.nowGrade);
      this.setOptions(2, result[0]);
      this.setOptions(1, result[1]);
      this.setOptions(0, result[2]);
    },
    async afterTx(userInfo, wallet, account) {
      const total = fromWei(userInfo.totalReward);
      this.setTotalReward(total);
      await this.showRefeat(userInfo, wallet, account);
      const myTokenAmount = await myBalance(account, wallet);
      this.setMyTokenBalance(myTokenAmount);

      this.overRay(false, true);
    },
    async showRefeat(userInfo, wallet, account) {
      const result = await getEarnShow(
        userInfo,
        this.membershipApy,
        this.USD,
        account,
        wallet
      );
      this.setDollar(result[0]);
      this.delRefresh();

      this.forReEarn = result[1];
      this.forReOnePlus = result[2];
      this.setRefresh();
    },
    setMyTokenBalance(myTokenAmount) {
      this.myTokenBalance = Number(toWei(myTokenAmount));
    },
    setTotalReward(total) {
      this.totalReward = Number(total).toFixed(2);
    },
    setOptions(num, msg) {
      this.options[num].notEnabled = msg;
    },
    setStakingTime(msg) {
      this.stakingTime = msg;
    },
    setAddressToDepositTime(msg) {
      this.addressToDepositTime = msg;
    },
    setWill1(msg) {
      this.will1 = msg;
    },
    setWill(msg) {
      this.will = msg;
    },
    setNowSeconds(msg) {
      this.nowSeconds = msg;
    },
    setDaySeconds(msg) {
      this.daySeconds = msg;
    },
    setRemainTime(msg) {
      this.remainTime = msg;
    },
    setSeeRemain(msg) {
      this.seeRemain = msg;
    },
    setDollar(msg) {
      this.dollar = msg;
    },
    overRay(msg, msg1) {
      this.showWait = msg;
      if (msg1) {
        this.waitMsg = "Please wait transaction...";
      } else {
        this.waitMsg = "Please wait...";
      }
    },
    //
    setFee50(msg) {
      this.depositFee50 = msg;
    },
    setUSD(msg) {
      this.USD = msg;
    },
    setNowGrade(msg) {
      this.nowGrade = msg;
    },
    setAllow(msg) {
      this.contractAllowance = msg;
    },
    setDeposit(msg) {
      this.addressToDeposit = msg;
    },
    setDepositD(msg) {
      this.depositD = msg;
    },
    setUpuser(msg) {
      this.upgradeUser = msg;
    },
    setOnGrade(msg) {
      this.onChainGrade = msg;
    },
    setIsMetamask(msg) {
      this.isMetamask = msg;
    },
  },
});
