<template>
  <div class="sideBlack pointer pb-4 d-flex align-items-center">
    <img :src="require(`@/assets/img/${imgLink}`)" alt="nav" class="iconSize" />

    <button class="mx-2 sideBlack d-flex align-items-center">
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "BarCommon",
  props: {
    imgLink: String,
    text: String,
  },
};
</script>
