// const noneColor = "iconColor1";
const noneColor3 = "iconColor3";
const useColor = "iconColor";
const myPage = "person_search_opsz20B";
const join = "group_add_opsz20B";
const house = "house20B";
const rock = "rockB";
const lottery = "local_activity_opsz20B";

export const colorBasicS = {
  a: myPage,
  b: join,
  c: "house20",
  d: rock,
  e: lottery,
  a1: useColor,
  a2: noneColor3,
  a3: noneColor3,
  a4: noneColor3,
  a5: noneColor3,
};

export const footColor = (page) => {
  switch (page) {
    case "0":
      return {
        a: myPage,
        b: join,
        c: "house20",
        d: rock,
        e: lottery,
        a1: useColor,
        a2: noneColor3,
        a3: noneColor3,
        a4: noneColor3,
        a5: noneColor3,
      };
    case "1":
      return {
        a: myPage,
        b: "group_add_opsz20",
        c: house,
        d: rock,
        e: lottery,
        a1: noneColor3,
        a2: useColor,
        a3: noneColor3,
        a4: noneColor3,
        a5: noneColor3,
      };
    case "2":
      return {
        a: "person_search_opsz20",
        b: join,
        c: house,
        d: rock,
        e: lottery,
        a1: noneColor3,
        a2: noneColor3,
        a3: useColor,
        a4: noneColor3,
        a5: noneColor3,
      };
    case "3":
      return {
        a: myPage,
        b: join,
        c: house,
        d: rock,
        e: lottery,
        a1: noneColor3,
        a2: noneColor3,
        a3: noneColor3,
        a4: noneColor3,
        a5: noneColor3,
      };
    case "7":
      return {
        a: myPage,
        b: join,
        c: house,
        d: "rock",
        e: lottery,
        a1: noneColor3,
        a2: noneColor3,
        a3: noneColor3,
        a4: useColor,
        a5: noneColor3,
      };
    case "8":
      return {
        a: myPage,
        b: join,
        c: house,
        d: rock,
        e: "local_activity_opsz20",
        a1: noneColor3,
        a2: noneColor3,
        a3: noneColor3,
        a4: noneColor3,
        a5: useColor,
      };
  }
};
