<template>
  <div :class="sortResult1()">
    <div
      class="pointer d-flex justify-content-end align-items-center"
      @click="$emit('showSort1', !showSort)"
    >
      {{ sortText }}
      <svg
        :class="showSort ? 'show3 ms-2' : 'show4 ms-2'"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 9.455 17.056 8 12 13.092 6.944 8 5.5 9.455
                      12 16z"
          fill="#888888"
          fill-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div :class="sortResult()">
      <slot name="button3"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SortButton",
  emits: ["showSort1"],
  props: {
    sortText: String,
    showSort: Boolean,
    langOrSort: Boolean,
  },
  methods: {
    sortResult() {
      if (this.showSort) {
        if (this.langOrSort) {
          return "hiddenSort show2";
        } else {
          return "hiddenSort show5";
        }
      } else {
        return "hiddenSort show1";
      }
    },
    sortResult1() {
      if (this.langOrSort) {
        return "sort";
      } else {
        return "sort1";
      }
    },
  },
};
</script>

<style scoped>
.sort {
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0px;
  margin-right: 10px;
}
.sort1 {
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0px;
  margin-right: 10px;
}
.hiddenSort {
  position: absolute;
  width: calc(100% - 2px);
  top: 30px;
  display: flex;
  border: 1px solid #ffde5f;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  transition: all 0.5s ease 0s;
}
.show1 {
  height: 0px;
  opacity: 0;
}
.show2 {
  height: 160px;
  z-index: 1;
  opacity: 1;
}
.show5 {
  height: 80px;
  opacity: 1;
}

.show3 {
  transition: transform 0.5s ease 0s;
  transform: rotate(180deg);
}

.show4 {
  transition: transform 0.5s ease 0s;
  transform: none;
}
</style>
