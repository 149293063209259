<template>
  <BigMain
    v-if="widthStore.windowWidth >= 769"
    @emitSetShow="emitSetShow"
    :account="accountStore.account"
    :rockResult="forMainStore.rockResult"
    :lotteryTotalBalance="forMainStore.lotteryTotalBalance"
    :useKey="bannerStore.cardKey"
  />
  <SmallMain
    v-else
    @emitSetShow="emitSetShow"
    :account="accountStore.account"
    :rockResult="forMainStore.rockResult"
    :lotteryTotalBalance="forMainStore.lotteryTotalBalance"
    :useKey="bannerStore.cardKey"
  />
</template>
<script>
import BigMain from "../components/mainFirst/BigMain.vue";
import SmallMain from "../components/mainFirst/SmallMain.vue";
import { useWidthStore } from "../stores/width";
import { useBannerStore } from "../stores/banner";
import { useNotionStore } from "../stores/notion";
import { useAccountStore } from "../stores/account";
import { usePageStore } from "../stores/nextPage";
import { useForMainStore } from "../stores/forMain";
import { axiosCall } from "../control/server";
import { api } from "../control/axios";
export default {
  name: "Main",
  components: {
    BigMain,
    SmallMain,
  },
  setup() {
    const widthStore = useWidthStore();
    const notionStore = useNotionStore();
    const bannerStore = useBannerStore();
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    const forMainStore = useForMainStore();
    return {
      widthStore,
      notionStore,
      bannerStore,
      pageStore,
      accountStore,
      forMainStore,
    };
  },
  async mounted() {
    if (!this.pageStore.mainFV) {
      this.pageStore.setMainVisit();
      const ser = await this.axiosCall();
      this.notionStore.notificationCopy(ser.c);
      this.notionStore.numListCopy(ser.updateA);
      await this.bannerStore.setting();
      const res = await this.api("/api/rockData", "get");
      this.forMainStore.setRockResult(res);
      const lotteryRes = await this.api("/api/lottery/getBalance", "get");
      this.forMainStore.setLottery(lotteryRes);
    }
  },
  data() {
    return {
      axiosCall,
      api,
    };
  },
  methods: {
    emitSetShow(msg) {
      this.pageStore.setShowSelect(msg);
    },
  },
};
</script>
