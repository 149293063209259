import Web3 from "web3";
import Caver from "caver-js";
import { myAsyncFunction } from "./sleep";
const node = process.env.VUE_APP_NODE;

export const callWeb3 = async (wallet) => {
  await myAsyncFunction(500);
  if (wallet === "true") {
    if (window.ethereum) {
      const web3 = new Web3(node);
      return web3;
    } else {
      const web3 = new Web3(node);
      return web3;
    }
  } else {
    if (window.klaytn) {
      const caver = new Caver(window.klaytn);
      return caver;
    }
  }

  // else {
  //   window.addEventListener('ethereum#initialized', handleEthereum, {
  //     once: true,
  //   })

  //   // If the event is not dispatched by the end of the timeout,
  //   // the user probably doesn't have MetaMask installed.
  //   const web3 = setTimeout(handleEthereum, 3000) // 3 seconds
  //   return web3
  // }
};

// const handleEthereum = () => {
//   const { ethereum } = window
//   if (ethereum && ethereum.isMetaMask) {
//     console.log('Ethereum successfully detected!')
//     // Access the decentralized web!
//     const web3 = new Web3(ethereum)
//     return web3
//   } else {
//     console.log('Please install MetaMask!')
//   }
// }
export const callCaver = () => {
  const caver = new Caver(window.klaytn);
  return caver;
};

export const callWeb31 = async (wallet) => {
  if (wallet === "true") {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      // console.log(web3)
      return web3;
    } else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      // console.log('Injected web3 detected.')
      return web3;
    }
  } else {
    if (window.klaytn) {
      const caver = new Caver(window.klaytn);
      return caver;
    }
  }

  // else {
  //   window.addEventListener('ethereum#initialized', handleEthereum, {
  //     once: true,
  //   })

  //   // If the event is not dispatched by the end of the timeout,
  //   // the user probably doesn't have MetaMask installed.
  //   const web3 = setTimeout(handleEthereum, 3000) // 3 seconds
  //   return web3
  // }
};
