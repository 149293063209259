import { defineStore } from "pinia";
import { callBasic } from "../control/server";

export const useBannerStore = defineStore("bannerS", {
  state: () => ({
    bannerList: [],
    cardKey: 0,
    gradeToImage: "card5.png",
  }),
  actions: {
    async setting() {
      this.bannerList = await callBasic();
      this.cardKey += 1;
    },
    changeImg(msg) {
      this.gradeToImage = msg;
      this.cardKey += 1;
    },
  },
});
