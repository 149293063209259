<template>
  <b-modal
    id="klip-modal"
    v-model="klipC"
    hide-footer
    hide-header
    centered
    :no-close-on-backdrop="true"
  >
    <!-- modal-header-close -->
    <div class="absoluteRight pointer" @click="$emit('klipChange', false)">
      <BIconX scale="1.5" variant="secondary"></BIconX>
    </div>
    <div class="fontPopins text-center">
      <div>
        <h2 class="mt-2 mb-4">{{ $t("message.klip0") }}</h2>
        <p class="fontSizeSm mb-0">{{ $t("message.klip1") }}</p>
        <p class="fontSizeSm">{{ $t("message.klip2") }}</p>
      </div>
      <b-img
        thumbnail
        class="iconSpace1"
        center
        alt="qr code"
        :src="canvas"
      ></b-img>
    </div>
  </b-modal>
</template>

<script>
import { BIconX } from "bootstrap-vue";
export default {
  name: "KlipModal",
  emits: ["klipChange"],
  components: {
    BIconX,
  },
  props: {
    klipC: Boolean,
    canvas: String,
  },
};
</script>
