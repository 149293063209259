import { defineStore } from "pinia";
import { useAdminStore } from "../stores/adminCheck";
import { accountCall } from "../control/metamask";
import { getAccount1 } from "../control/kaikas";
import { UserAddress } from "../control/userAddress";

export const useAccountStore = defineStore("accountS", {
  state: () => ({
    account: "",
  }),
  getters: {
    userAddress: (state) => UserAddress(state.account),
  },

  actions: {
    async setting(msg) {
      const adminStroe = useAdminStore();
      switch (msg) {
        case "true":
          this.account = await accountCall();
          await adminStroe.setting(this.account);
          window.ethereum.on("accountsChanged", async () => {
            if (this.account != null) {
              window.location.reload();
            }
          });
          window.ethereum.on("chainChanged", () => {
            if (this.account != null) {
              window.location.reload();
            }
            window.location.reload();
          });

          break;

        case "false":
          this.account = await getAccount1();
          await adminStroe.setting(this.account);
          window.klaytn.autoRefreshOnNetworkChange = true;
          window.klaytn.on("accountsChanged", async () => {
            if (this.account != null) {
              window.location.reload();
            }
          });

          break;
      }
    },
    klipAccount(msg) {
      this.account = msg;

      const adminStroe = useAdminStore();
      adminStroe.setting(msg);
    },
  },
});
