import axios from "axios";

export const apiKlip = async (url) => {
  return await axios.get(url).catch((error) => {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      // alert("요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.");
      // alert(error.response.data);
      // alert(error.response.status);
      // alert(error.response.headers);
      console.log(
        "요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다."
      );
    } else if (error.request) {
      // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
      // node.js에서는 http.ClientRequest 인스턴스입니다.
      console.log(error.request);
      // alert("요청이 전송되었지만, 응답이 수신되지 않았습니다");
      // alert(JSON.stringify(error.request));

      console.log("요청이 전송되었지만, 응답이 수신되지 않았습니다");
    } else {
      // console.log("Error", error.message);
      // alert("오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.");
      alert(error.message);

      console.log("오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.");
    }
    // alert(JSON.stringify(error.config));

    console.log(error.config);
  });
};
