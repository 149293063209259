import { callCaver, callWeb3 } from "./getWeb3";
import {
  getRockContract,
  tokenAddress,
  getLotteryContract,
} from "./contractData";
import { getAccount1 } from "./kaikas";

const rock = process.env.VUE_APP_ROCK;
const lottery = process.env.VUE_APP_LOTTERY;
const deligator = process.env.VUE_APP_DELIGATOR;
const gas = "8500000";
const rockSABI = {
  inputs: [
    {
      internalType: "uint256",
      name: "select",
      type: "uint256",
    },
    {
      internalType: "uint256",
      name: "newBet",
      type: "uint256",
    },
  ],
  name: "rock",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};
const tokenAABI = {
  inputs: [
    { internalType: "address", name: "_spender", type: "address" },
    { internalType: "uint256", name: "_value", type: "uint256" },
  ],
  name: "approve",
  outputs: [{ name: "", type: "bool" }],
  stateMutability: "nonpayable",
  type: "function",
};

export const getSingedTx = async (num, bet, account) => {
  const caver = callCaver();
  const tx = await caver.rpc.klay.signTransaction({
    // type: "TxTypeFeeDelegatedSmartContractExecution",
    type: "FEE_DELEGATED_SMART_CONTRACT_EXECUTION",
    from: account,
    to: rock,
    data: caver.abi.encodeFunctionCall(rockSABI, [num, bet]),
    gas: gas,
    feePayer: deligator,
  });
  return tx;
};

export const getSingedAppove = async (account, spender) => {
  const caver = callCaver();
  const tx = await caver.rpc.klay.signTransaction({
    // type: "TxTypeFeeDelegatedSmartContractExecution",
    type: "FEE_DELEGATED_SMART_CONTRACT_EXECUTION",
    from: account,
    to: tokenAddress,
    data: caver.abi.encodeFunctionCall(tokenAABI, [
      spender,
      "50000000000000000000000",
    ]),
    gas: gas,
    feePayer: deligator,
  });
  return tx;
};

export const getBalance = async (address) => {
  const web3 = await callWeb3("true");
  const balance = await web3.eth.getBalance(address);
  return balance;
};

export const getBlock = async () => {
  const web3 = await callWeb3("true");
  // const result = await web3.rpc.klay.getBlockNumber();
  const result = await web3.eth.getBlockNumber();

  if (!result) {
    alert(
      "kaikas wallet error(문제가 지속되면 브라우저를 끄고 다시 실행 해주세요)"
    );
    window.location.reload();
  }
  // console.log(result);
  // const result1 = web3.utils.hexToNumber(result);

  return result;
};
// account 추가해야됨
export const getRock = async (account) => {
  const contract = await getRockContract();
  const info = await contract.methods.getBasic(account).call();
  return info;
  // [회차, 전체 캡, 이번회차 전체 게임수, 유저 캡, 이번회차 유저 게임수, 유저 배팅 배율]
  // [count, dailyCap, todayTotal, userCap, numbering[count][msg.sender], user[msg.sender].bet];
};

export const enterLottery = async () => {
  const caver = await callCaver();
  const senderAddress = await getAccount1();
  const tx = await caver.rpc.klay.signTransaction({
    // type: "TxTypeFeeDelegatedSmartContractExecution",
    type: "FEE_DELEGATED_SMART_CONTRACT_EXECUTION",
    from: senderAddress,
    to: lottery,
    data: caver.abi.encodeFunctionCall({
      inputs: [],
      name: "enter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    }),
    gas: gas,
    feePayer: deligator,
  });
  return tx;
};

export const getLottery = async () => {
  const contract = await getLotteryContract();
  let lotteryInfo;
  try {
    lotteryInfo = await contract.methods.getAll().call();
  } catch (error) {
    console.log(lotteryInfo);
  }
  return lotteryInfo;
};
