import Vue from "vue";
import VueRouter from "vue-router";
import Metamask from "../views/Metamask.vue";
import Main from "../views/Main.vue";
import { checkAll } from "../control/admin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Metamask,
    children: [
      {
        path: "/",
        name: "Main",
        component: Main,
      },
      {
        path: "/membership",
        name: "Membership",
        component: () =>
          import(/* webpackChunkName: "membership" */ "@/views/Membership.vue"),
      },
      {
        path: "/game",
        name: "Game",
        component: () =>
          import(/* webpackChunkName: "game" */ "@/views/Game.vue"),
      },
      {
        path: "/stake",
        name: "Stake",
        component: () =>
          import(/* webpackChunkName: "stake" */ "@/views/Stake.vue"),
      },
      {
        path: "/swap",
        name: "Swap",
        component: () =>
          import(/* webpackChunkName: "swap" */ "@/views/Swap.vue"),
      },
      {
        path: "/rock",
        name: "Rock",
        component: () =>
          import(/* webpackChunkName: "swap" */ "@/views/Rock.vue"),
      },
      {
        path: "/lottery",
        name: "Lottery",
        component: () =>
          import(/* webpackChunkName: "swap" */ "@/views/Lottery.vue"),
      },
      {
        path: "/admin",
        name: "Admin",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/views/Admin.vue"),
        beforeEnter: async (to, from, next) => {
          const result = await checkAll();
          if (result) {
            next();
          } else {
            next("/");
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
