import { qrCode } from "./qrCode";
import { prepare } from "klip-sdk";
import { tokenAddress, membership, rock, lottery } from "./contractData";

const bapp = "DLP MEMBERSHIP";
const approve =
  '{"constant":false,"inputs":[{"name":"_spender","type":"address"},{"name":"_addedValue","type":"uint256"}],"name":"increaseApproval","outputs":[{"name":"success","type":"bool"}],"payable":false,"stateMutability":"nonpayable","type":"function"}';
const claim =
  '{"inputs":[],"name":"claim","outputs":[],"stateMutability":"nonpayable","type":"function"}';
const deposit =
  '{"inputs":[{"internalType":"uint256","name":"newGrade","type":"uint256"},{"internalType":"uint256","name":"newAmount","type":"uint256"}],"name":"deposit","outputs":[],"stateMutability":"nonpayable","type":"function"}';
const withdrawal =
  '{"inputs":[],"name":"withdrawal","outputs":[],"stateMutability":"nonpayable","type":"function"}';
const withdrawal24 =
  '{"inputs":[],"name":"withdrawal24","outputs":[],"stateMutability":"nonpayable","type":"function"}';
const rockABI =
  '{"inputs":[{"internalType":"uint256","name":"select","type":"uint256"},{"internalType":"uint256","name":"newBet","type":"uint256"}],"name":"rock","outputs":[],"stateMutability":"nonpayable","type":"function"}';
const enterABI =
  '{"inputs": [],"name": "enter","outputs": [],"stateMutability": "nonpayable","type": "function"}';
// const membershipLink = "https://www.deliquidity.co.kr/membership";
// const rockLink = "https://www.deliquidity.co.kr/rock";

export const mobile = () => {
  const user = navigator.userAgent;
  let mob = false;
  if (
    user.indexOf("iPhone") > -1 ||
    user.indexOf("Android") > -1 ||
    user.indexOf("iPad") > -1 ||
    user.indexOf("iPod") > -1
  ) {
    mob = true;
  }

  return mob;
};

export const accountKlip = async () => {
  const bappName = bapp;
  const res = await prepare.auth({ bappName });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipApprove = async (bappName, spender) => {
  // const bappName = bapp;
  const to = tokenAddress; // 파라미터로 받아? 테스트넷 없음 고정.
  const value = "0";
  const abi = approve;
  const params = `["${spender}","30000000000000000000000000"]`;
  // const success = basicLink;
  // const fail = basicLink;
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipClaim = async () => {
  const bappName = bapp;
  const to = membership;
  const value = "0";
  const abi = claim;
  const params = "[]";
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipDeposit = async (newGrade, newAmount) => {
  const bappName = bapp;
  const to = membership;
  const value = "0";
  const abi = deposit;
  const params = `["${newGrade}","${newAmount}"]`;
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipWithdrawal = async () => {
  const bappName = bapp;
  const to = membership;
  const value = "0";
  const abi = withdrawal;
  const params = "[]";
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipWithdrawal24 = async () => {
  const bappName = bapp;
  const to = membership;
  const value = "0";
  const abi = withdrawal24;
  const params = "[]";
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipRock = async (myRock, bet) => {
  const bappName = "DLP ROCK";
  const to = rock; // 파라미터로 받아? 테스트넷 없음 고정.
  const value = "0";
  const abi = rockABI;
  const params = `["${myRock}","${bet}"]`;
  // const successLink = rockLink;
  // const failLink = rockLink;
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
    // successLink,
    // failLink,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};

export const klipLotteryEnter = async () => {
  const bappName = "DLP Lottery";
  const to = lottery; // 파라미터로 받아? 테스트넷 없음 고정.
  const value = "0";
  const abi = enterABI;
  const params = "[]";
  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    console.log(res.err);
    return { state: false, canvas: null, key: null };
  } else if (res.request_key) {
    const canvas = await qrCode(
      `https://klipwallet.com/?target=/a2a?request_key=${res.request_key}`
    );
    return { state: true, canvas: canvas, key: res.request_key };
  }
};
