//라디오 버튼 컨트롤
export const radioConTF = (nowGrade) => {
  switch (nowGrade) {
    case "Tropical Sun":
      return [true, true, true];
    case "Cherry Red":
      return [true, true, false];
    case "Ocean Blue":
      return [true, false, false];
    case "none":
      return [false, false, false];
  }
};
