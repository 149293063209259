import { api } from "../control/axios";
import { useAccountStore } from "../stores/account";

async function realCheck1(list) {
  const result = await api("/api/user", "post", {
    param: [list],
  });
  return result;
}

export const check = async (account) => {
  const result = await realCheck1(account);
  return result;
};

export const checkAll = async () => {
  try {
    const accountStore = useAccountStore();
    const result = await realCheck1(accountStore.account);
    return result;
  } catch (error) {
    return false;
  }
};
