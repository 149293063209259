import { defineStore } from "pinia";

export const useForMainStore = defineStore("forMainS", {
  state: () => ({
    rockResult: [],
    lotteryTotalBalance: 0,
  }),
  actions: {
    setRockResult(msg) {
      this.rockResult = msg;
    },
    setLottery(msg) {
      this.lotteryTotalBalance = msg;
    },
  },
});
