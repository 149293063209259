import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASEURL;

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8;";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const api = async (url, method, data1) => {
  // 서버 연결
  // if (typeof data1 !== undefined) {
  //   alert("data : " + JSON.stringify(data1));
  // }
  return (
    await axios({
      method: method,
      url: url,
      data: data1,
    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        // alert("요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.");
        // alert(error.response.data);
        // alert(error.response.status);
        // alert(error.response.headers);
        console.log(
          "요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다."
        );
      } else if (error.request) {
        // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
        // node.js에서는 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
        // alert("요청이 전송되었지만, 응답이 수신되지 않았습니다");
        // alert(JSON.stringify(error.request));

        console.log("요청이 전송되었지만, 응답이 수신되지 않았습니다");
      } else {
        // console.log("Error", error.message);
        // alert("오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.");
        alert(error.message);

        console.log("오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.");
      }
      // alert(JSON.stringify(error.config));

      console.log(error.config);
    })
  ).data;
};
// https 인증 전에 깃 클론 하고 필요한 셋팅 다 설치
// export const api = async (url, method, data) => {
//   const myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json;charset=utf-8;");
//   // myHeaders.append("Access-Control-Allow-Origin", "*");
//   // console.log(method);
//   // 옵션 기본 값은 *로 강조
//   const response = await fetch(process.env.VUE_APP_BASEURL + url, {
//     method: method, // *GET, POST, PUT, DELETE 등
//     // mode: "cors",
//     // credentials: "same-origin",
//     headers: myHeaders,
//     // {
//     //   "Content-Type": "application/json;charset=utf-8;",
//     //   "Access-Control-Allow-Origin": "*",
//     //   // 'Content-Type': 'application/x-www-form-urlencoded',
//     // },
//     body: JSON.stringify(data), // body의 데이터 유형은 반드시 "Content-Type" 헤더와 일치해야 함
//   }).catch((error) => {
//     console.log(error);
//   });
//   console.log(response);
//   return response.json(); // JSON 응답을 네이티브 JavaScript 객체로 파싱
// };
