<template>
  <b-modal
    id="modal-scoped"
    v-model="pageStore.showSelect"
    hide-footer
    hide-header
    centered
    :no-close-on-backdrop="true"
  >
    <!-- modal-header-close -->
    <div class="absoluteRight pointer" @click="pageStore.setShowSelect(false)">
      <BIconX scale="1.5" variant="secondary"></BIconX>
    </div>
    <div
      class="d-flex flex-wrap justify-content-around text-center fontPopins px-2 py-4"
    >
      <div>
        <h2 class="mt-2 mb-4">{{ $t("message.first11") }}</h2>
        <p class="fontSizeSm mb-0">
          {{ $t("message.newFirst2") }}
        </p>
        <p class="fontSizeSm">
          {{ $t("message.newFirst3") }}
        </p>
      </div>

      <div
        class="d-flex justify-content-center align-items-center mt-4 ban2 mema"
        @click="callMetamask()"
      >
        <b-img src="../../assets/img/mema-s.png" alt="metamask"></b-img>
        <span class="ms-2">{{ $t("message.first12") }}</span>
      </div>
      <div
        class="d-flex justify-content-center align-items-center mt-4 ban2 kaikas"
        @click="callKaikas()"
      >
        <b-img src="../../assets/img/kaikas-s.png" alt="kaikas"></b-img>
        <span class="ms-2">{{ $t("message.first13") }}</span>
      </div>

      <div
        class="d-flex justify-content-center align-items-center mt-4 ban2 klip"
        @click="klip()"
      >
        <b-img src="../../assets/img/Combined Shape.png" alt="klip"></b-img>
        <span class="ms-2">{{ $t("message.klip0") }}</span>
      </div>

      <!-- <div>
        <button
          class="iconColor firstButton1 borderRadius ban2"
          @click="callMetamask()"
        >
          <b-img
            fluid
            width="100"
            class="m-3"
            src="../../assets/img/metamask.png"
          ></b-img>
          <p class="fontSizeSm mb-1">
            {{ $t("message.first12") }}
          </p>
        </button>
        <button
          class="iconColor firstButton1 borderRadius ban2"
          @click="callKaikas()"
        >
          <b-img
            fluid
            width="100"
            class="m-3 p-1"
            src="../../assets/img/symbol_multi_solid.png"
          ></b-img>
          <p class="fontSizeSm mb-1">
            {{ $t("message.first13") }}
          </p>
        </button>
      </div> -->
    </div>

    <KlipModal :klipC="klipC" :canvas="canvas" @klipChange="klipChange" />
  </b-modal>
</template>

<script>
import { request } from "klip-sdk";
import { BIconX } from "bootstrap-vue";
import KlipModal from "../klip/KlipModal.vue";
import networks from "../../assets/data/networks.json";
import modalData from "../../assets/data/modal.json";
import { usePageStore } from "../../stores/nextPage";
import { useAccountStore } from "../../stores/account";
import { useNotionStore } from "../../stores/notion";
import { useContractStore } from "../../stores/contractView";
import {
  getChainId,
  membership,
  tokenAddress,
} from "../../control/contractData";
import { connect } from "../../control/link.js";
import { mobile, accountKlip } from "../../control/klip";
import { qrCode } from "../../control/qrCode";
import { addNetwork } from "../../control/metamask";
import { apiKlip } from "../../control/axiosKlip";
import { getBalance } from "../../control/caverG";

export default {
  name: "FirstModal",
  emits: ["pageSwitchChange", "showMsgBoxTwo"],
  components: {
    BIconX,
    KlipModal,
  },
  data() {
    return {
      networks,
      modalData,
      getChainId,
      connect,
      request,
      qrCode,
      membership,
      tokenAddress,
      mobile,
      accountKlip,
      addNetwork,
      apiKlip,
      getBalance,
      canvas: null,
      canvas1: null,
      klipC: false,
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const contractStore = useContractStore();
    const notionStore = useNotionStore();
    const pageStore = usePageStore();
    return { accountStore, contractStore, notionStore, pageStore };
  },
  methods: {
    async selectNetwork(wallet) {
      await this.accountStore.setting(wallet);
      // 메타마스크 모바일은 숫자, 카이카스 모바일은 문자형으로 줘서 ==

      await this.pageCaNotion();
    },

    async switchNetwork(networks, wallet) {
      if (wallet == "true") {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networks.ChainId }],
        });
      } else if (wallet == "false") {
        await window.klaytn.sendAsync(
          {
            method: "wallet_switchKlaytnChain",
            params: [{ chainId: networks.ChainId }],
          },
          (err, result) => console.log(err, result)
        );
      }
    },

    async kaikasBSwitch(networks) {
      try {
        await window.klaytn.request({
          method: "wallet_switchKlaytnChain",
          params: [
            {
              chainId: networks.ChainId,
            },
          ],
        });
      } catch (err) {
        console.log(err);
        this.pageStore.setShowSelect(false);
        alert(this.$t("message.mef6"));
      }
    },

    async callMetamask() {
      sessionStorage.setItem("wallet", "true");
      this.contractStore.setIsMetamask(true);
      // 메타마스크 있어
      if (typeof window.ethereum !== "undefined") {
        const network3 = await this.getChainId();
        // 클레이튼 밎이
        if (String(network3) === process.env.VUE_APP_NETWORK) {
          await this.selectNetwork("true");
          // 클레이튼 아니야
        } else {
          try {
            await this.switchNetwork(this.networks.Klaytn, "true");
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await this.addNetwork(this.networks.Klaytn);
              } catch (addError) {
                console.log(addError);
                alert("add network error");
                window.location.reload();
              }
            }
            console.log(switchError);
            alert("switch network error");
            window.location.reload();
          }
          // 모바일에서 네트워크 추가로는 안됨
          await this.selectNetwork("true");
        }
        // 메타마스크 없어
      } else {
        // 모바일이야
        if (this.mobile()) {
          // 안드로이드
          // IOS
          this.install(
            this.$t("message.first15"),
            this.$t("message.first16"),
            this.mobileOS()
          );
          // pc야
        } else {
          this.install(
            this.$t("message.first9"),
            this.$t("message.first10"),
            process.env.VUE_APP_METAMASKINSTALL
          );
        }
      }
    },

    async callKaikas() {
      sessionStorage.setItem("wallet", "false");
      this.contractStore.setIsMetamask(true);
      // 카이카스 있어
      if (typeof window.klaytn !== "undefined") {
        const network3 = window.klaytn.networkVersion;
        // 클레이튼
        if (String(network3) === process.env.VUE_APP_NETWORK) {
          await this.selectNetwork("false");
          //클레이튼 아님
        } else {
          try {
            await this.switchNetwork(this.networks.Klaytn, "false");
          } catch (error) {
            console.log(error);
            this.kaikasBSwitch(this.networks.Klaytn);
          }
        }
        // 카이카스 없어
      } else {
        // 모바일 이야
        if (this.mobile()) {
          // 카이카스 딥링크
          this.connect(
            "https://app.kaikas.io/u/https://finance.deliquidity.com"
          );
          // pc야
        } else {
          this.install(
            this.$t("message.first14"),
            this.$t("message.first10"),
            process.env.VUE_APP_KAIKASINSTALL
          );
        }
      }
    },

    install(text, link, address) {
      const h = this.$createElement;
      const hashShort = link;
      // h('div', ['hello', h('a')])
      const msg = h("div", [
        text,
        h("a", {
          domProps: {
            innerHTML: hashShort,
            href: address,
            target: "_blank",
          },
        }),
      ]);
      this.$emit("showMsgBoxTwo", msg);
      // this.showMsgBoxTwo(msg);
    },

    async klip() {
      // 컨트랙트 정보 호출은 퍼블릭노드 통해서 하고(올댓노드 기본 rps5라서 안씀), tx 보낼때만 klip사용
      sessionStorage.setItem("wallet", "true");
      this.contractStore.setIsMetamask(false);
      // prepare
      const res = await this.accountKlip();

      if (!res.state) {
        this.showMsgBoxTwo("Klip 연결 실패");
      } else if (this.mobile()) {
        // 모바일
        // request
        this.request(res.key, () => alert("mobile plz"));
        // result
        this.getKlipAccount(res.key);
      } else {
        // pc
        this.canvas = res.canvas;
        this.klipC = true;
        // request
        this.getKlipAccount(res.key);
      }
    },

    getKlipAccount(key) {
      let timer = setInterval(async () => {
        // request
        const result = await this.apiKlip(
          `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${key}`
        );
        // wait result
        if (result.data.status === "completed") {
          clearInterval(timer);
          // alert(result.data.result.klaytn_address);
          await this.klipBasic(result.data.result.klaytn_address);
        }
      }, 2100);
    },

    async klipBasic(address) {
      const account = address;
      this.accountStore.klipAccount(account);
      this.klipC = false;

      await this.pageCaNotion();
    },

    async pageCaNotion() {
      const gasFee = await this.getBalance(this.accountStore.account);
      // 가스비로 쓸 돈이 없으면 알림
      if (gasFee == "0") {
        alert(this.$t("message.main8"));
      }
      this.pageStore.setShowSelect(false);
      this.$emit("pageSwitchChange", true);
      // 이건 멤버십 이동할때 여기선 계정 등록만.
      // await this.contractStore.start(account);
    },

    mobileOS() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return process.env.VUE_APP_METAMASKAPP1;
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return process.env.VUE_APP_METAMASKAPP2;
      }
    },

    // 모달창 ------------------------------------------------
    // this$bvModal을 못찾아서 여기둠
    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");
      this.$bvModal.msgBoxOk(msg, modal);
    },

    klipChange(msg) {
      this.klipC = msg;
    },
  },
};
</script>
<style scoped>
.ban2 {
  width: 80%;
  color: #212529;
  height: 56px;
  padding: 0 15%;
  border-radius: 4px;
  cursor: pointer;
}

.firstButton1 {
  background-color: white;
  border-color: #ffde5f;
}

.klip {
  border-color: #216fea;
  background-color: #216fea;
  color: white;
}
.mema {
  border-color: #ffc32c;
  background-color: #ffc32c;
}
.kaikas {
  border-color: #cdffaa;
  background-color: #cdffaa;
}
@media screen and (max-width: 576px) {
  .ban2 {
    width: 90%;
  }
}
</style>
