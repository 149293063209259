import QRCode from "qrcode";

export const qrCode = async (text) => {
  // 여기 await 이 안먹혀서 따로 안빼둠
  try {
    const result = await QRCode.toDataURL(text);
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};
