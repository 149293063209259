<template>
  <div class="px-0">
    <!-- 모달시작 -->
    <FirstModal @pageSwitchChange="pageSwitchChange" />
    <!-- 모달 끝 -->
    <!-- <br /> -->
    <b-overlay :show="contractStore.showWait" rounded="sm">
      <div class="row justify-content-center m-0">
        <div class="px-0">
          <!-- 메인 화면 상단 -->
          <header class="headerFix">
            <!-- 화면 타이틀 및 메인화면 되돌아가기 -->
            <div v-if="widthStore.windowWidth >= 1024">
              <MainHeaderBig
                :switchLA="pageSwitch"
                @addTokenCall="addTokenCall"
              />
            </div>
            <div v-else>
              <MainHeaderSmall :switchLA1="pageSwitch" />
              <!-- 사이드바 시작 -->
              <Sidebar @addTokenCall="addTokenCall" />
            </div>
            <!-- 화면 타이틀 및 메인화면 되돌아가기 -->
          </header>
          <!-- 메인 화면 상단 끝 -->
          <!-- 메인 화면 시작 -->
          <div class="container-lg">
            <!-- 트랜잭션 중 오버래이 되는 화면 -->
            <!-- 메인 화면 카드 -->
            <div :aria-hidden="contractStore.showWait ? 'true' : null">
              <!-- <div class="sideFixed" v-if="widthStore.windowWidth >= 1024">
                <MainLeftLeftSide @addTokenCall="addTokenCall" />
              </div> -->

              <!-- 메인 화면 -->
              <b-card pills border-variant="white">
                <div v-if="widthStore.windowWidth < 1024">
                  <!-- 넓이 1024 이하일 때 화면 이동 버튼 -->
                  <MainFrstSmallMove />
                  <!-- 넓이 1024 이상일 때 화면 이동 버튼 끝-->
                </div>
                <main>
                  <router-view />
                </main>
              </b-card>
              <!-- 메인 화면 끝 -->
            </div>
          </div>

          <!-- col 끝 -->
        </div>
        <!-- row 끝 -->
      </div>
      <!-- 컨테이너 끝 -->
      <template #overlay>
        <MainOverlay />
      </template>
    </b-overlay>
  </div>
</template>
<script>
import "../style/Index.css";
import FirstModal from "../components/firstPage/FirstModal.vue";
import MainHeaderBig from "../components/mainHeader/Big.vue";
import MainHeaderSmall from "../components/mainHeader/Small.vue";
import Sidebar from "../components/mainHeader/Sidebar.vue";
// import MainLeftLeftSide from "../components/mainLeft/LeftSide.vue";
import MainFrstSmallMove from "../components/mainFirst/SmallMove.vue";
import MainOverlay from "../components/Overlay.vue";
import { usePageStore } from "../stores/nextPage";
import { useWidthStore } from "../stores/width";
import { useContractStore } from "../stores/contractView";
import { useAccountStore } from "../stores/account";

import { addToken } from "../control/metamask";
import { addToken1 } from "../control/kaikas";
import { mobile } from "../control/klip";

import { lang, openInNewTab } from "../control/basicSet";
export default {
  name: "Metamask",
  components: {
    FirstModal,
    MainHeaderBig,
    MainHeaderSmall,
    Sidebar,
    // MainLeftLeftSide,
    MainFrstSmallMove,
    MainOverlay,
  },
  setup() {
    const pageStore = usePageStore();
    const widthStore = useWidthStore();
    const contractStore = useContractStore();
    const accountStore = useAccountStore();

    return { pageStore, widthStore, contractStore, accountStore };
  },
  data() {
    return {
      lang,
      openInNewTab,
      addToken,
      addToken1,
      mobile,
      pageSwitch: false,
      // canvas: null,
    };
  },
  created() {
    const result = this.lang();
    if (result == "ko") {
      this.$i18n.locale = "kr";
    } else {
      this.$i18n.locale = "en";
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
    // 안되면 트라이 캐치
    const result = sessionStorage.getItem("nextPage");
    if (result) {
      this.pageStore.gogo(Number(result));
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    // 컴포넌트 에밋----------------------------------------------
    // 첫화면
    pageSwitchChange(msg) {
      // sessionStorage.setItem("pageSwitch", msg);
      this.pageSwitch = msg;
    },

    addTokenCall() {
      const wallet = sessionStorage.getItem("wallet");
      if (wallet === "true") {
        this.addToken();
      } else {
        if (this.mobile()) {
          if (this.accountStore.account !== "") {
            this.addToken1();
          } else {
            alert("Connect wallet plz");
          }
        } else {
          this.addToken1();
        }
      }
    },

    // 화면 넓이
    onResize() {
      this.widthStore.setting(window.innerWidth);
    },
  },
};
</script>
<style scoped>
.container-lg {
  max-width: 1024px !important;
  margin-top: 100px;
}

.headerFix {
  position: fixed;
  width: 100vw;
  z-index: 6;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: white;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.38);
}
.sideFixed {
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 5;
}
.paddingLeft > div {
  padding-left: 0;
}
.possitionRight {
  width: 80%;
  margin-left: 20%;
}
@media screen and (max-width: 1023px) {
  .container-lg {
    padding: 0rem !important;
    margin-top: 0px;
  }
  .headerFix {
    position: inherit;
    width: auto;
    z-index: none;
    padding: 8px 8px 0;
    background-color: none;
  }
}
@media screen and (max-width: 768px) {
  .paddingLeft > div {
    padding-left: 1rem;
  }

  .possitionRight {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 374px) {
  .allFontSize {
    font-size: 12px;
  }
}
</style>
