import { getTokenContract, getTokenContract1 } from "./contractData";
import { fromWei, BN } from "./fromWei";

//토큰 양 확인
export const myBalance = async (address, wallet) => {
  const contract = await getTokenContract(wallet);
  let balance = await contract.methods.balanceOf(address).call();
  balance = fromWei(balance);

  return balance;
};

//토큰 심볼 확인
// export const mySymbol = async () => {
//   console.log("mySymbol");
//   const contract = await getTokenContract();
//   let symbol = await contract.methods.symbol().call();

//   return symbol;
// };

//토큰 사용 가능 확인
/// 컨트랙트 하나로 묶어서 이 함수를 다 사용 가능하게 approve도 마찬가지
export const allowance = async (spender, owner, wallet) => {
  //토큰 컨트랙트
  const contract = await getTokenContract(wallet);

  let allow = await contract.methods.allowance(owner, spender).call();
  allow = BN(allow).toString();

  return allow;
};

export const approve = async (spender, owner) => {
  const contract = await getTokenContract1("false");
  try {
    await contract.methods.approve(spender, "30000000000000000000000000").send({
      from: owner,
      gas: "8500000",
      gasPrice: 25000000000,
    });
  } catch (error) {
    console.log(error);
  }
};

export const increaseApprove = async (spender, owner, wallet) => {
  const contract = await getTokenContract1(wallet);
  console.log(contract);
  if (wallet === "true") {
    await contract.methods
      .increaseApproval(spender, "30000000000000000000000000")
      .send({
        from: owner,
        gas: "8500000",
        gasPrice: 25000000000,
      })
      .on("error", (err) => {
        console.log(err);
        return false;
      });
  } else {
    try {
      await contract.methods
        .increaseApproval(spender, "30000000000000000000000000")
        .send({
          from: owner,
          gas: "8500000",
          gasPrice: 25000000000,
        });
    } catch (error) {
      console.log(error);
      return false;
    }
    return false;
  }
};
