export const getLanguage = () => {
  return navigator.language || navigator.userLanguage;
};

export const lang = () => {
  let currentLang = getLanguage();

  currentLang = currentLang.substr(0, 2);

  return currentLang;
};

export const openInNewTab = (url) => {
  const win = window.open(url, "_blank");
  win.focus();
};

// html 태그에서 사용하려고
export const clearRefresh = (interval) => {
  clearInterval(interval);
};
