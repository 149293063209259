<template>
  <div class="position-relative main">
    <div class="notion-img">
      <b-carousel
        id="carousel-fade"
        no-animation
        :interval="4000"
        controls
        label-prev=""
        label-next=""
      >
        <b-carousel-slide
          v-for="(list, n) in bannerStore.bannerList"
          :key="n"
          class="notion-box"
          :background="data1(list)"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <div class="evenlyPlz pointer" @click="openInNewTab(carouselLink)">
      <div class="d-flex align-items-center">
        <img src="../../assets/img/campaign_black_18dp.svg" alt="notion" />
        <strong>
          {{ notionCut }}
        </strong>
        <strong>
          {{ notionCut3 > 18 ? "..." : "." }}
        </strong>
      </div>
      <div>
        <strong> {{ notionCut2 }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { useNotionStore } from "../../stores/notion";
import { useBannerStore } from "../../stores/banner";
import { openInNewTab } from "../../control/basicSet";
export default {
  name: "Notion",
  mounted() {
    this.textChange();
  },
  destroyed() {
    clearInterval(this.noti);
  },
  setup() {
    const notionStore = useNotionStore();
    const bannerStore = useBannerStore();

    return { notionStore, bannerStore };
  },
  data() {
    return {
      openInNewTab,
      noti: null,
      textChangeNum: 0,

      notionCut: "DLP",
      notionCut2: "00-00",
      notionCut3: 5,
      carouselLink: "https://www.deliquidity.com/",
    };
  },

  methods: {
    // 공지 내용 돌아가며 변경
    textChange() {
      this.setCarousel();

      clearInterval(this.noti);
      this.noti = setInterval(() => {
        if (this.textChangeNum < this.notionStore.lengthN) {
          this.setCarousel();
          this.textChangeNum++;
        } else {
          this.textChangeNum = 0;
        }
      }, 4000);
    },
    setCarousel() {
      if (this.notionStore.lengthN == 0) {
        this.notionCut = "DLP";
        this.notionCut2 = "00-00";
        this.notionCut3 = 5;
        this.carouselLink = "https://www.deliquidity.com/";
      } else {
        const result = this.notionStore.getCarousel(this.textChangeNum);

        this.notionCut = result.main.slice(0, 18);
        this.notionCut2 = result.date.slice(5, 10);
        this.notionCut3 = result.main.length;
        this.carouselLink = result.link;
      }
    },
    data1(list) {
      return `url(${process.env.VUE_APP_BASEURL}/api/download/${list})`;
    },
  },
};
</script>
<style scoped>
.main {
  height: 100%;
}
.notion-box {
  height: 312px;
  width: 480px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 20px;
}
.notion-img {
  border-radius: 20px;
  height: 100%;
  background-image: url(../../assets/img/card5.png);
  background-position: center;
  background-size: cover;
}
.evenlyPlz {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  color: white;
  line-height: 2;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.7;
  bottom: 0;
  border-radius: 0 0 20px 20px;
}

@media screen and (max-width: 1023px) {
  .evenlyPlz {
    font-size: 12px;
    line-height: 3;
  }
  .notion-box {
    height: 247px;
    width: 380px;
  }
}
@media screen and (max-width: 992px) {
  .notion-box {
    height: 221px;
    width: 340px;
  }
}
@media screen and (max-width: 768px) {
  .notion-box {
    height: calc((100vw - 2.1rem) / 1.54);
    width: calc(100vw - 2.1rem);
    border-radius: 0;
  }
  .notion-img {
    border-radius: 0;
  }
  .evenlyPlz {
    border-radius: 0;
  }
}
@media screen and (max-width: 576px) {
  .evenlyPlz {
    font-size: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>
