<template>
  <div class="pointer d-flex justify-content-between alignCenter">
    <!-- 화면 크기에 따른 MEF 로고 변화 -->
    <div class="d-flex align-items-center" @click="pageStore.gogo(0), route1()">
      <img src="../../assets/img/LOGO_XS.png" alt="logo" />
      <!-- 화면 크기에 따른 MEF 로고 변화 끝-->
      <span class="dustar">
        <div>DE:LIQUIDITY</div>
      </span>
    </div>
    <!-- 로그아웃 시작 -->
    <div>
      <b-button
        v-if="!switchLA1"
        class="btn buttonColor buttonFontSize btn-secondary topBtn"
        size="sm"
        @click="logIn()"
      >
        connect wallet
        <!-- <img src="../../assets/img/logout_black_24dp.svg" alt="" /> -->
      </b-button>
      <b-button
        v-b-toggle.sidebar-right
        class="buttonAmpty buttonBorder1 p-0"
        size="sm"
      >
        <img src="../../assets/img/menu_black_24dp.svg" alt="menu"
      /></b-button>
      <!-- 로그아웃 끝 -->
    </div>
  </div>
</template>
<script>
import { usePageStore } from "../../stores/nextPage";
export default {
  name: "Small",
  props: {
    switchLA1: Boolean,
  },
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  methods: {
    logIn() {
      this.pageStore.setShowSelect(true);
    },
    route1() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
  },
};
</script>
