<template>
  <div class="bigMain">
    <section class="mainBanner">
      <div class="container-md">
        <div class="text">
          <div class="title">De:Liquidity FINANCE</div>
          <div class="description">
            {{ $t("message.first6") }}<br />
            {{ $t("message.first7") }}
          </div>
          <b-button
            class="btn buttonColor buttonFontSize btn-secondary"
            :disabled="account != ''"
            @click="$emit('emitSetShow', true)"
          >
            Connect Wallet
          </b-button>
        </div>
        <div class="notion-box">
          <MainFrstNotion :key="useKey" />
        </div>
      </div>
    </section>

    <section class="gameInfo">
      <div class="container-md">
        <span> {{ $t("message.main0") }}</span>

        <div class="info">
          <div class="info-box">
            <img src="../../assets/img/rock.svg" alt="rock" />
            <div>
              <div class="box-title dustar">{{ $t("message.main1") }}</div>
              <div class="box-description">
                {{ $t("message.main2") }} {{ rockResult[2] }}% ,
                {{ $t("message.main3") }} {{ rockResult[1] }}% ,
                {{ $t("message.main4") }} {{ rockResult[0] }}%
              </div>
            </div>
            <b-button
              class="btn buttonColor buttonFontSize btn-secondary"
              @click="$router.push({ path: '/rock' })"
            >
              {{ $t("message.main5") }} GO!
            </b-button>
          </div>

          <div class="info-box">
            <img
              src="../../assets/img/local_activity_opsz20.svg"
              alt="lottery"
            />
            <div>
              <div class="box-title dustar">{{ $t("message.main6") }}</div>
              <div class="box-description">{{ lotteryTotalBalance }} DLP</div>
            </div>
            <b-button
              class="btn buttonColor buttonFontSize btn-secondary"
              @click="$router.push({ path: '/lottery' })"
            >
              {{ $t("message.main7") }} GO!
            </b-button>
          </div>

          <div class="info-box ing">
            <img src="../../assets/img/ing.png" alt="comming soon" />
            <div class="box-title dustar">Comming Soon</div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import MainFrstNotion from "./Notion.vue";
import Footer from "../basicUse/footer.vue";
export default {
  name: "BigMain",
  components: {
    MainFrstNotion,
    Footer,
  },
  emits: ["emitSetShow"],
  props: {
    account: String,
    rockResult: Array,
    lotteryTotalBalance: Number,
    useKey: Number,
  },
};
</script>
<style scoped>
.bigMain {
  top: -5px;
  width: calc(100vw + 1rem);
  position: absolute;
  margin-left: calc(-50vw + 50%);
  left: -1rem;
}
.mainBanner {
  height: 600px;
  background-color: #f8f8f8;
}
.mainBanner > .container-md {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

button {
  color: #212529;
  font-size: 15px;
  font-weight: 600;
  width: 12rem;
  border-radius: 3px;
  line-height: 2.5;
}
.text {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.title {
  font-size: 50px;
  font-weight: 900;
}
.description {
  font-size: 20px;
  font-weight: 500;
}
.notion-box {
  position: relative;
  height: 312px;
  width: 480px;
}

.gameInfo {
  height: 700px;
}

.gameInfo > .container-md {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.container-md > span {
  font-size: 30px;
  font-weight: 700;
}

.info {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.info-box {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.info-box > img {
  height: 25%;
}
.box-description {
  font-size: 18px;
  margin: auto;
  width: 80%;
}
.ing > img {
  height: 47%;
  margin-left: 10%;
}

@media screen and (max-width: 1199px) {
  .info-box {
    width: 25%;
  }
}

@media screen and (max-width: 1023px) {
  .lottery {
    background-size: cover;
    height: 80vh;
    padding: 4vh 0 0;
    width: 100%;
    margin: 0;
  }
  .title {
    font-size: 35px;
  }
  .description {
    font-size: 16px;
  }
  .notion-box {
    height: 247px;
    width: 380px;
  }
}

@media screen and (max-width: 1023px) {
  .lottery {
    background-size: cover;
    height: 80vh;
    padding: 4vh 0 0;
    width: 100%;
    margin: 0;
  }
  .title {
    font-size: 35px;
  }
  .description {
    font-size: 18px;
  }
  .notion-box {
    height: 247px;
    width: 380px;
  }
}

@media screen and (max-width: 992px) {
  .notion-box {
    height: 221px;
    width: 340px;
  }
  .description {
    font-size: 15px;
  }
  .title {
    font-size: 30px;
  }
  .text {
    height: 40%;
  }

  .info {
    height: 45%;
  }
  .info-box {
    width: 30%;
  }
  button {
    width: 9rem;
  }
  .dustar {
    font-size: 18px;
  }
  .box-description {
    font-size: 15px;
  }
}
</style>
