<template>
  <div class="smallMain">
    <section class="mainBanner">
      <div class="text">
        <div class="title">De:Liquidity FINANCE</div>
        <div class="description">
          {{ $t("message.first6") }}<br />
          {{ $t("message.first7") }}
        </div>
        <b-button
          class="btn buttonColor buttonFontSize btn-secondary"
          :disabled="account != ''"
          @click="$emit('emitSetShow', true)"
        >
          Connect Wallet
        </b-button>
      </div>
      <div class="notion-box">
        <MainFrstNotion :key="useKey" />
      </div>
    </section>

    <section class="gameInfo">
      <span> {{ $t("message.main0") }}</span>

      <b-carousel id="info" fade :interval="4000" class="info" indicators>
        <b-carousel-slide class="info-box">
          <img
            class="info-box-img"
            src="../../assets/img/rock.svg"
            alt="rock"
          />
          <div>
            <div class="box-title dustar">{{ $t("message.main1") }}</div>
            <div class="box-description">
              {{ $t("message.main2") }} {{ rockResult[2] }}% ,
              {{ $t("message.main3") }} {{ rockResult[1] }}% ,
              {{ $t("message.main4") }} {{ rockResult[0] }}%
            </div>
          </div>
          <b-button
            class="btn buttonColor buttonFontSize btn-secondary"
            @click="$router.push({ path: '/rock' })"
          >
            {{ $t("message.main5") }} GO!
          </b-button>
        </b-carousel-slide>
        <b-carousel-slide class="info-box">
          <img
            class="info-box-img"
            src="../../assets/img/local_activity_opsz20.svg"
            alt="lottery"
          />
          <div>
            <div class="box-title dustar">{{ $t("message.main6") }}</div>
            <div class="box-description">{{ lotteryTotalBalance }} DLP</div>
          </div>
          <b-button
            class="btn buttonColor buttonFontSize btn-secondary"
            @click="$router.push({ path: '/lottery' })"
          >
            {{ $t("message.main7") }} GO!
          </b-button>
        </b-carousel-slide>
        <b-carousel-slide class="info-box">
          <img
            class="info-box-img ing"
            src="../../assets/img/ing.png"
            alt="comming soon"
          />
          <div class="box-title dustar">Comming Soon</div>
        </b-carousel-slide>
      </b-carousel>
    </section>
  </div>
</template>
<script>
import MainFrstNotion from "./Notion.vue";
export default {
  name: "SmallMain",
  components: {
    MainFrstNotion,
  },
  props: {
    account: String,
    rockResult: Array,
    lotteryTotalBalance: Number,
    useKey: Number,
  },
  emits: ["emitSetShow"],
};
</script>

<style scoped>
.mainBanner {
  background-color: #f8f8f8;
  text-align: center;
  padding: 50px 0 0;
}
button {
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  width: 90%;
  border-radius: 3px;
  line-height: 2;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 50px;
}
.title {
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 20px;
}
.description {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}
.notion-box {
  position: relative;
  height: calc((100vw - 2rem) / 1.54);
  width: calc(100vw - 2.1rem);
}
.gameInfo {
  text-align: center;
  padding: 50px 0 80px;
}

.gameInfo > span {
  font-size: 24px;
  font-weight: 700;
}
.info {
  margin: auto;
  height: 290px;
  margin: 20px 0;
  display: flex;
  margin: 20px;
}

.info-box {
  position: absolute;
  height: 97%;
  width: 70%;
  text-align: center;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  right: 0;
  margin: auto;
}
.info-box-img {
  width: 20%;
}
.box-title {
  margin: 15px 0;
}
.box-description {
  font-size: 14px;
  margin-bottom: 15px;
  color: #212529;
}
.ing.info-box-img {
  width: 36%;
  margin-left: 10%;
}

@media screen and (max-width: 576px) {
  button {
    font-size: 15px;
  }

  .title {
    font-size: 28px;
  }
  .description {
    font-size: 12px;
  }
  .info-box-img {
    width: 40%;
  }
  .ing.info-box-img {
    width: 80%;
    margin-left: 10%;
  }
}
</style>
