<template>
  <b-button size="sm" class="buttonAmpty buttonBorder1 font17">
    {{ text }}
  </b-button>
</template>

<script>
export default {
  name: "TopCommon",
  props: {
    text: String,
  },
};
</script>
<style scoped>
.font17 {
  font-size: 17px !important;
  font-weight: 600 !important;
}
</style>
